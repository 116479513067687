import { useEffect, useMemo } from 'react'

import { Dropdown } from 'react-bootstrap'

import { Pericope } from '../../resources/ExegeticalResources'

const dropdownItems = (pericopesMap: Map<string, Pericope[]>): { id?: string; title: string }[] => {
    return Array.from(pericopesMap.values())
        .flatMap((pericopes) => pericopes)
        .flatMap((pericope) => pericope.steps)
        .map(({ id, title }) => ({ id, title }))
}

interface ExegeticalResourceSelectorProps {
    pericopesMap: Map<string, Pericope[]>
    stepId: string
    setStepId: (id: string) => void
    idSuffix: string
}

export const ExegeticalResourceSelector = ({
    pericopesMap,
    stepId,
    setStepId,
    idSuffix
}: ExegeticalResourceSelectorProps) => {
    const items = useMemo(() => dropdownItems(pericopesMap), [pericopesMap])
    const selectedItem = useMemo(() => items.find((item) => stepId === item.id), [items, stepId])

    useEffect(() => {
        if (!selectedItem && items.length > 1 && items[1].id) {
            setStepId(items[1].id)
        }
    }, [items, selectedItem, setStepId])

    return (
        <Dropdown id={`resources-dropdown-${idSuffix}`} className="resources-select" align="end">
            <Dropdown.Toggle className="sl-dropdown styled-dropdown-select">
                {selectedItem?.title ?? ''}
            </Dropdown.Toggle>
            <Dropdown.Menu className="scrollable-dropdown styled-dropdown-select-menu">
                {items.map(({ id, title }) =>
                    id ? (
                        <Dropdown.Item onClick={() => setStepId(id)} active={id === stepId} key={id}>
                            <div className="resources-dropdown-item">
                                <div className="resources-dropdown-header">
                                    <span className="resources-dropdown-title">{title}</span>
                                </div>
                            </div>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Header key={title}>{title}</Dropdown.Header>
                    )
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

import { IDBPDatabase, openDB } from 'idb'
import JSZip from 'jszip'

import { getExportFileNamePrefix } from './ResourceExporter'
import { CacheType, FileExtension, CacheSource } from '../../../types'
import { TRANSLATION_RESOURCES_DATABASE_NAME } from '../../app/TranslationResourceCachingContext'

export enum ResourceImportError {
    FileValidationFailed = 'FileValidationFailed',
    GenericFailure = 'GenericFailure'
}

interface ZipEntry {
    source: CacheSource
    relativePath: string
    zipEntry: JSZip.JSZipObject
}

const getCacheName = ({ relativePath }: ZipEntry) => {
    const pathParts = relativePath.split('/')

    // relative path should look something like cacheStorage/avtt-resources-public/3.json or translationResources/images.json
    if (pathParts.length > 3) {
        throw new Error('Too many parts')
    }

    const fileName = pathParts[1]
    return fileName.split(FileExtension.JSON)[0]
}

const deserializeCacheStorage = async (entry: ZipEntry) => {
    const { zipEntry } = entry
    const cachedFileContents = await zipEntry.async('string')
    const cacheMap = JSON.parse(cachedFileContents)
    const { url, response } = cacheMap

    if (typeof url !== 'string') {
        throw new Error('url is not a string')
    }

    if (!response) {
        throw new Error('No response was stored in cache entry')
    }

    const buffer = response.body ? new Uint8Array(response.body).buffer : undefined

    delete response.body

    const newResponse = new Response(buffer, { ...response })

    const cacheName = getCacheName(entry)
    const cache = await caches.open(cacheName)
    await cache.put(url, newResponse)
}

const deserializeCacheIndex = async (entry: ZipEntry, db: IDBPDatabase<unknown>) => {
    const { zipEntry } = entry
    const cachedFileContents = await zipEntry.async('string')
    const entries = JSON.parse(cachedFileContents)

    if (!Array.isArray(entries)) {
        throw new Error('Cached entry is not an array')
    }

    const cacheName = getCacheName(entry)

    for (const { key, generatedAt } of entries) {
        await db.put(cacheName, generatedAt, key)
    }
}

const deserializeCache = async (entry: ZipEntry, db: IDBPDatabase<unknown>) => {
    const { source } = entry
    if (source === CacheSource.CacheStorage) {
        return deserializeCacheStorage(entry)
    }
    if (source === CacheSource.CacheIndex) {
        return deserializeCacheIndex(entry, db)
    }
}

const readFile = async (file: File) => {
    const zip = new JSZip()
    const newZip = await zip.loadAsync(file)
    const entries: ZipEntry[] = []

    newZip.forEach((relativePath, zipEntry) => {
        if (!zipEntry.dir) {
            const isCacheStorageEntry = relativePath.startsWith(`${CacheSource.CacheStorage}/`)
            const isTranslationResourcesEntry = relativePath.startsWith(`${CacheSource.CacheIndex}/`)
            if (isCacheStorageEntry) {
                entries.push({ source: CacheSource.CacheStorage, relativePath, zipEntry })
            } else if (isTranslationResourcesEntry) {
                entries.push({ source: CacheSource.CacheIndex, relativePath, zipEntry })
            }
        }
    })

    return entries
}

export const isResourceExportFileName = (fileName: string) =>
    fileName.startsWith(getExportFileNamePrefix()) && fileName.endsWith(FileExtension.ZIP)

// TODO: what to do if TranslationResources db entries get imported successfully, but other cache entries are not?
export const importResources = async (zipFile: File) => {
    if (!isResourceExportFileName(zipFile.name)) {
        throw new Error(ResourceImportError.FileValidationFailed)
    }

    const entries = await readFile(zipFile)

    const db = await openDB(TRANSLATION_RESOURCES_DATABASE_NAME, 1, {
        upgrade(theDb) {
            Object.values(CacheType).forEach((cache) => theDb.createObjectStore(cache))
        }
    })

    // Use Promise.allSettled instead of Promise.all. Promise.all will reject when any of the
    // promises passed to it reject. Promise.allSettled resolves when all of the promises
    // settle.
    const results = await Promise.allSettled(entries.map((entry) => deserializeCache(entry, db)))
    if (results.some((result) => result.status === 'rejected')) {
        throw new Error(ResourceImportError.GenericFailure)
    }
}

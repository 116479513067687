import { FC, PropsWithChildren, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { isVerticalSplit, isHorizontalSplit, RightPaneSplit, RightPaneId } from './TranslationEditor'
import { Passage } from '../../models3/Passage'
import { Portion } from '../../models3/Portion'
import { Root } from '../../models3/Root'
import PassageBiblicalTermsViewer from '../biblicalTerms/PassageBiblicalTermsViewer'
import { EnhancedResourceViewer } from '../enhancedResources/EnhancedResourceViewer'
import { ExegeticalResourceViewer } from '../exegeticalResources/ExegeticalResourceViewer'
import { Images } from '../images/Images'
import PassageDocuments from '../passages/PassageDocuments'
import { HorizontalSplitButton, PaneCloseButton, SlttHelp, VerticalSplitButton } from '../utils/Buttons'
import ErrorBoundary from '../utils/Errors'
import {
    ImageReferencesIcon,
    ExegeticalResourcesIcon,
    PublishedBiblesIcon,
    StarIcon,
    PassageResourcesIcon
} from '../utils/Icons'

import 'react-tabs/style/react-tabs.css'

const PASSAGE_RESOURCE = 'passageResource'
const EXEGETICAL_RESOURCE = 'exegeticalResource'
const ENHANCED_RESOURCE = 'enhancedResource'
const TRANSLATION_RIGHT_PANE_TAB = 'resourcesPaneTabIndex'

type Props = PropsWithChildren<{
    portion: Portion | null
    passage: Passage | null
    className: string
}>

export const ShowResourceIfPortionAndPassageDefined: FC<Props> = ({ portion, passage, className, children }) => {
    const { t } = useTranslation()

    if (!portion) {
        return <div className={className}>{t('noPortionOrPassageStudyResourceMessage')}</div>
    }

    if (!passage) {
        return <div className={className}>{t('noPassageStudyResourceMessage')}</div>
    }

    return <div className={className}>{children}</div>
}

// This component is wrapped in observer, meaning it will rerender whenever any observable
// properties it accesses during rendering change. This will cause children components to
// rerender if they aren't wrapped in observer.
const TranslationRightPane = observer(
    ({
        rt,
        rightPaneSplit,
        setRightPaneSplit,
        showClosePaneButton,
        paneId,
        isPlaying,
        setIsPlaying
    }: {
        rt: Root
        rightPaneSplit: RightPaneSplit
        setRightPaneSplit: (value: RightPaneSplit) => void
        showClosePaneButton?: boolean
        paneId: RightPaneId
        isPlaying: string
        setIsPlaying: (isPlaying: string) => void
    }) => {
        const [tabIndex, setTabIndex] = useState(
            parseInt(rt.getDefault(`${paneId}.${TRANSLATION_RIGHT_PANE_TAB}`) || '0')
        )
        const { t } = useTranslation()

        const { passage, passageReferences } = rt

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _rev = passage?._rev

        const _setTabIndex = (index: number) => {
            rt.setDefault(`${paneId}.${TRANSLATION_RIGHT_PANE_TAB}`, index.toString())
            setTabIndex(index)
        }

        const splitButton = (newSplit: RightPaneSplit) => {
            if (!isHorizontalSplit(newSplit) && !isVerticalSplit(newSplit)) {
                return null
            }

            const ButtonComponent = isHorizontalSplit(newSplit) ? HorizontalSplitButton : VerticalSplitButton

            const baseProps = {
                buttonClassName: 'split-button',
                className: 'split-icon',
                tooltip: t('splitPane'),
                enabled: true,
                onClick: () => setRightPaneSplit(newSplit)
            }

            return (
                <ButtonComponent
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...baseProps}
                />
            )
        }

        const closePaneButtonOnClick = () => {
            const splitMapping: Record<RightPaneSplit, RightPaneSplit> = {
                [RightPaneSplit.TripleHorizontal]: RightPaneSplit.DoubleHorizontal,
                [RightPaneSplit.TripleVertical]: RightPaneSplit.DoubleVertical,
                [RightPaneSplit.DoubleHorizontal]: RightPaneSplit.Single,
                [RightPaneSplit.DoubleVertical]: RightPaneSplit.Single,
                [RightPaneSplit.Single]: RightPaneSplit.Single // to avoid type error
            }

            setRightPaneSplit(splitMapping[rightPaneSplit])
        }

        return (
            <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => {
                    _setTabIndex(index)
                }}
                className="right-pane-tabs"
                selectedTabPanelClassName="right-pane-tabs__tab-panel--selected"
                forceRenderTabPanel
            >
                <TabList className="right-pane-tabs__tab-list">
                    <Tab
                        key="passage-docs"
                        className="right-pane-tabs__tab"
                        selectedClassName="right-pane-tabs__tab--selected"
                    >
                        <PassageResourcesIcon className="right-pane-tab-icon" tooltip={t('Passage resources')} />
                    </Tab>
                    <Tab
                        key="signs"
                        className="right-pane-tabs__tab"
                        selectedClassName="right-pane-tabs__tab--selected"
                    >
                        <PublishedBiblesIcon className="right-pane-tab-icon" tooltip={t('publishedBibles')} />
                    </Tab>
                    <Tab
                        key="exegetical-resources"
                        className="right-pane-tabs__tab"
                        selectedClassName="right-pane-tabs__tab--selected"
                    >
                        <ExegeticalResourcesIcon className="right-pane-tab-icon" tooltip={t('exegeticalResources')} />
                    </Tab>
                    <Tab
                        key="image-references"
                        className="right-pane-tabs__tab"
                        selectedClassName="right-pane-tabs__tab--selected"
                    >
                        <ImageReferencesIcon className="right-pane-tab-icon" tooltip={t('bibleImages')} />
                    </Tab>
                    <Tab
                        key="passage-key-terms"
                        className="right-pane-tabs__tab"
                        selectedClassName="right-pane-tabs__tab--selected"
                    >
                        <SlttHelp id="biblical-terms-passage" tooltip={t('passageBiblicalTermsHeader')} place="bottom">
                            <StarIcon className="right-pane-tab-icon passage-key-terms-icon" />
                        </SlttHelp>
                    </Tab>
                    <span className="split-button-wrapper">
                        {rightPaneSplit === RightPaneSplit.Single && (
                            <>
                                {splitButton(RightPaneSplit.DoubleHorizontal)}
                                {splitButton(RightPaneSplit.DoubleVertical)}
                            </>
                        )}
                        {showClosePaneButton && (
                            <>
                                {rightPaneSplit === RightPaneSplit.DoubleHorizontal &&
                                    splitButton(RightPaneSplit.TripleHorizontal)}
                                {rightPaneSplit === RightPaneSplit.DoubleVertical &&
                                    splitButton(RightPaneSplit.TripleVertical)}
                                <PaneCloseButton
                                    className="close-pane-button"
                                    onClick={closePaneButtonOnClick}
                                    tooltip={t('Close pane')}
                                    enabled
                                />
                            </>
                        )}
                    </span>
                </TabList>

                <TabPanel key="passage-docs">
                    <ErrorBoundary>
                        <ShowResourceIfPortionAndPassageDefined
                            portion={rt.portion}
                            passage={rt.passage}
                            className="passage-document-viewer"
                        >
                            {passage && (
                                <PassageDocuments
                                    {...{
                                        rt,
                                        passage,
                                        displayEditButtons: paneId === RightPaneId.Primary,
                                        persistenceTag: `${paneId}.${PASSAGE_RESOURCE}`
                                    }}
                                />
                            )}
                        </ShowResourceIfPortionAndPassageDefined>
                    </ErrorBoundary>
                </TabPanel>

                <TabPanel key="published-bibles">
                    <ErrorBoundary>
                        <EnhancedResourceViewer
                            rt={rt}
                            persistenceTag={`${paneId}.${ENHANCED_RESOURCE}`}
                            idSuffix={paneId}
                            passageReferences={passageReferences}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                        />
                    </ErrorBoundary>
                </TabPanel>

                <TabPanel key="exegetical-resources">
                    <ErrorBoundary>
                        <ExegeticalResourceViewer
                            persistenceTag={`${paneId}.${EXEGETICAL_RESOURCE}`}
                            idSuffix={paneId}
                            passageReferences={passageReferences}
                        />
                    </ErrorBoundary>
                </TabPanel>

                <TabPanel key="image-references">
                    <ErrorBoundary>
                        <Images rt={rt} passageReferences={passageReferences} />
                    </ErrorBoundary>
                </TabPanel>

                <TabPanel key="passage-key-terms">
                    <ErrorBoundary>
                        <ShowResourceIfPortionAndPassageDefined
                            portion={rt.portion}
                            passage={rt.passage}
                            className="passage-biblical-terms"
                        >
                            <PassageBiblicalTermsViewer
                                rt={rt}
                                passage={rt.passage}
                                passageVideo={rt.passageVideo}
                                project={rt.project}
                                portion={rt.portion}
                            />
                        </ShowResourceIfPortionAndPassageDefined>
                    </ErrorBoundary>
                </TabPanel>
            </Tabs>
        )
    }
)

export default TranslationRightPane

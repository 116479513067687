import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
    ExegeticalResourceBookIndex,
    fetchExegeticalResourceBookIndexes,
    fetchPericopeMediaIndex,
    getExegeticalResourcePaths,
    PericopeMediaIndex
} from '../../resources/ExegeticalResources'
import { MediaType } from '../../types'

interface ExegeticalResourcesContextValue {
    exegeticalResourceBookIndexes?: ExegeticalResourceBookIndex
    pericopeMediaIndexes?: PericopeMediaIndex
    doesExegeticalResourceExist: (mediaType: MediaType, bookId?: string) => boolean
    exegeticalResourceMediaTypes: MediaType[]
}

const ExegeticalResourcesContext = createContext<ExegeticalResourcesContextValue | undefined>(undefined)

export const ExegeticalResourcesProvider: FC = ({ children }) => {
    const {
        i18n: { language }
    } = useTranslation()
    const [exegeticalResourceBookIndexes, setExegeticalResourceBookIndexes] = useState<ExegeticalResourceBookIndex>()
    const [pericopeMediaIndexes, setPericopeMediaIndexes] = useState<PericopeMediaIndex>()

    useEffect(() => {
        const fetchIndexes = async () => {
            try {
                const [indexes, pericopeIndexes] = await Promise.all([
                    fetchExegeticalResourceBookIndexes(language),
                    fetchPericopeMediaIndex()
                ])
                setExegeticalResourceBookIndexes(indexes)
                setPericopeMediaIndexes(pericopeIndexes)
            } catch (error) {
                console.error(`Error while fetching exegetical resource book indexes for language ${language}.`, error)
            }
        }
        fetchIndexes()
    }, [language])

    const doesExegeticalResourceExist = useCallback(
        (mediaType: MediaType, bookId?: string) => {
            if (!exegeticalResourceBookIndexes || !pericopeMediaIndexes) {
                return false
            }

            const bookNumber = bookId ? parseInt(bookId) : undefined

            return (
                getExegeticalResourcePaths({
                    language,
                    bookIndexes: exegeticalResourceBookIndexes.bookIndexes,
                    pericopeMedia: pericopeMediaIndexes.pericopeMedia,
                    bookNumber,
                    mediaType
                }).length > 0
            )
        },
        [exegeticalResourceBookIndexes, pericopeMediaIndexes, language]
    )

    const exegeticalResourceMediaTypes = useMemo(
        () => Object.values(MediaType).filter((mediaType) => doesExegeticalResourceExist(mediaType)),
        [doesExegeticalResourceExist]
    )

    return (
        <ExegeticalResourcesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                exegeticalResourceBookIndexes,
                pericopeMediaIndexes,
                doesExegeticalResourceExist,
                exegeticalResourceMediaTypes
            }}
        >
            {children}
        </ExegeticalResourcesContext.Provider>
    )
}

export const useExegeticalResources = () => {
    const context = useContext(ExegeticalResourcesContext)
    if (!context) {
        throw new Error('useExegeticalResources must be used within a ExegeticalResourcesProvider')
    }
    return context
}

import { t } from 'i18next'
import { observable, computed } from 'mobx'
import _ from 'underscore'

import { BiblicalTermMarker } from './BiblicalTermMarker'
import { newerThanCutoffDate, IDateFormatter } from './DateUtilities'
import { DBObject } from './DBObject'
import { FfmpegParameters } from './FfmpegParameters'
import { IDB } from './IDB'
import { MediaSlice } from './MediaSlice'
import { Passage } from './Passage'
import { PassageGloss } from './PassageGloss'
import { PassageHighlight } from './PassageHighlight'
import { PassageNote } from './PassageNote'
import { MIN_SEGMENT_LENGTH, PassageSegment } from './PassageSegment'
import { PassageSegmentLabel } from './PassageSegmentLabel'
import { PassageVideoMarker, PassageVideoMarkerType } from './PassageVideoMarker'
import { Portion } from './Portion'
import { Project } from './Project'
import { ReferenceMarker } from './ReferenceMarker'
import { ReviewProject } from './ReviewProject'
import { Timeline } from './RootBase'
import { limit, normalizeUsername, remove, smallNoteMarker, largeNoteMarker } from './Utils'
import { LocalStorageKeys } from '../components/app/slttAvtt'
import { fmt } from '../components/utils/Fmt'
import { isCloseEnough } from '../components/utils/Helpers'
import { HIDDEN_SEGMENT_LENGTH_SECONDS } from '../components/utils/Wav'
import { ViewableVideoCollection } from '../components/video/ViewableVideoCollection'
import { RefRange, refToChapterId, refRangesMinMax, refRangesToDisplay } from '../resources/RefRange'
import { refToUsfmBookHeader, refRangeToUsfmLabel } from '../resources/Usfm'
import { ExportTextFormat, SegmentDocumentType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

const intest = localStorage.getItem(LocalStorageKeys.INTEST) === 'true'

// Adjust for hidden segments
const getPreviousNonHiddenSegmentIndex = (segments: PassageSegment[], index: number) => {
    let i = index
    while (!segments[i].isVisible()) {
        i -= 1
        if (i <= 0) {
            break
        }
    }
    return i
}

export class VideoSlice {
    constructor(public video: PassageVideo, public position: number, public endPosition: number, public src: string) {}
}

type Marker = ReferenceMarker | BiblicalTermMarker

type ExportTextParams = {
    passage: Passage
    project: Project
    exportTextFormat?: ExportTextFormat
    includeHeader?: boolean
    previousChapterId?: string
}

export class PassageVideo extends DBObject {
    url = ''

    duration = 0 // Duration as filmed.

    @observable title = ''

    @observable computedDuration = 0 // Duration taking into account adjustments to segment length.

    isPatch = false

    @observable ffmpegParametersUsed?: FfmpegParameters

    @observable version = 0

    // This field is taken from the 'id' (NOT! _id) field of ProjectTask.
    @observable status = ''

    @observable _rev = 0

    @observable notes: PassageNote[] = []

    @observable segments: PassageSegment[] = []

    @observable glosses: PassageGloss[] = []

    @observable highlights: PassageHighlight[] = []

    @observable references: ReferenceMarker[] = []

    @observable biblicalTermMarkers: BiblicalTermMarker[] = []

    // email address of people who have viewed this
    @observable viewedBy: string[] = []

    @observable uploaded = false

    @observable mimeType = ''

    // These items are not persisted in online store
    @computed get isCompressed() {
        return !!this.ffmpegParametersUsed
    }

    @computed get resolution() {
        const resElement = this.ffmpegParametersUsed?.videoFilters.find((el) => el.startsWith('scale'))
        if (resElement === undefined) {
            return -1
        }

        const num = parseInt(resElement.slice(resElement.search(':') + 1))
        if (isNaN(num) || !isFinite(num)) {
            return -1
        }

        return num
    }

    @computed get crf() {
        if (!this.ffmpegParametersUsed) {
            return -1
        }
        const searchString = '-crf '
        const crfElement = this.ffmpegParametersUsed.outputOptions.find((el) => el.startsWith(searchString))
        if (crfElement === undefined) {
            return -1
        }

        const num = parseInt(crfElement.slice(searchString.length))
        if (isNaN(num) || !isFinite(num)) {
            return -1
        }

        return num
    }

    segmentTimesSet = false

    constructor(_id: string, db: IDB, creationDate?: Date) {
        super(_id, db)
        let creationDateString = this.creationDate
        if (creationDate) {
            creationDateString = db.getDate(creationDate)
        }
        this.creationDate = creationDateString
        this.setUMTRank()

        this.addSegment = this.addSegment.bind(this)
        this.timeToPosition = this.timeToPosition.bind(this)
    }

    toSnapshot() {
        const snapshot = this.toDocument()
        snapshot.segments = this.getAllBaseSegments().map((segment) => segment.toSnapshot())
        snapshot.notes = this.notes.map((note) => note.toSnapshot())
        snapshot.glosses = this.glosses.map((gloss) => gloss.toSnapshot())

        return snapshot
    }

    resetSegmentTimes() {
        this.segmentTimesSet = false
    }

    toDocument() {
        const {
            url,
            duration,
            status,
            isPatch,
            version,
            viewedBy,
            uploaded,
            rank,
            ffmpegParametersUsed,
            mimeType,
            title
        } = this
        return this._toDocument({
            url,
            duration,
            status,
            isPatch,
            version,
            viewedBy,
            uploaded,
            rank,
            ffmpegParametersUsed: JSON.stringify(ffmpegParametersUsed),
            mimeType,
            title
        })
    }

    dbg(passage: Passage | null, details?: string) {
        const doc = this.toDocument()

        if (details?.includes('n')) {
            doc.notes = this.notes.map((note) => note.dbg())
        }

        if (details?.includes('g')) {
            doc.glosses = this.glosses.map((gloss) => gloss.dbg())
        }

        if (details?.includes('s')) {
            doc.segments = this.getAllBaseSegments().map((segment) => segment.dbg(passage, details))
        } else {
            doc.segments = this.dbgSegments()
        }

        return doc
    }

    dbgSegments() {
        return this.getAllBaseSegments().map((s, i) => ({
            segment: `${i + 1} time=${s.time?.toFixed(2)} pos=[${s.position?.toFixed(2)}..${s.endPosition?.toFixed(
                2
            )}]`,
            videoPatchHistory: s.videoPatchHistory
        }))
    }

    copy() {
        let copy = new PassageVideo(this._id, this.db, new Date(this.creationDate))
        copy = Object.assign(copy, this)
        if (this.ffmpegParametersUsed) {
            copy.ffmpegParametersUsed = this.ffmpegParametersUsed.copy()
        }
        copy.viewedBy = Array.from(this.viewedBy)
        copy.segments = this.segments.map((segment) => segment.copy())
        copy.references = this.references.map((ref) => ref.copy())
        copy.notes = this.notes.map((note) => note.copy())
        copy.glosses = this.glosses.map((gloss) => gloss.copy())
        copy.highlights = this.highlights.map((hgh) => hgh.copy())
        return copy
    }

    async setTitle(newTitle: string) {
        const title = newTitle.trim()
        if (this.title === title) {
            return
        }

        const doc = this._toDocument({})
        doc.title = title
        await this.db.put(doc)
    }

    label(passage: Passage, excludeVersion = false) {
        return this.title.length
            ? this.title
            : !excludeVersion
            ? `${t('Version')} ${passage.getVersionIndex(this) + 1}`
            : passage.getVersionIndex(this) + 1
    }

    async setIsPatch() {
        if (this.isPatch) {
            return
        }
        const doc = this._toDocument({})
        doc.isPatch = true
        await this.db.put(doc)
    }

    async updateVersion() {
        const doc = this._toDocument({})
        doc.version = this.version + 1
        await this.db.put(doc)
    }

    async addViewedBy(email: string) {
        const { viewedBy } = this
        email = normalizeUsername(email)

        if (email === '' || this.creator === email || viewedBy.includes(email)) return

        const doc = this._toDocument({})
        // Force the _id for a task change to be different than the _id for the base passage
        doc._id += '@viewedby'

        doc.viewedByUser = email
        await this.db.put(doc)
    }

    // Only used when fixing incorrect duration
    async setDuration(duration: number) {
        if (duration === this.duration) {
            return
        }
        log('setDuration', fmt({ duration }))

        const doc = this._toDocument({})
        doc.duration = duration
        await this.db.put(doc)

        const baseSegments = this.getAllBaseSegments()

        // Legacy recordings don't have a hidden segment
        if (baseSegments.length === 1) {
            await baseSegments[0].setPositions(0, duration, this)
            return
        }

        // Newer recordings have a hidden segment at the end
        if (baseSegments.length === 2 && baseSegments[1].isHidden) {
            // Need to set positions in reverse order because a segment's position
            // has rules based on the other segments around it
            await baseSegments[1].setPositions(duration - HIDDEN_SEGMENT_LENGTH_SECONDS, duration, this)
            await baseSegments[0].setPositions(0, duration - HIDDEN_SEGMENT_LENGTH_SECONDS, this)
        }
    }

    createNote(position: number) {
        const creationDate = this.db.getNewId(this.notes, new Date(Date.now()))
        const _id = `${this._id}/${creationDate}`
        const note = new PassageNote(_id, this.db)
        if (intest) position = Math.round(position * 1000) / 1000 // stop jitter from failing test

        note.position = position
        note.setDefaultStartPosition()
        note.setDefaultEndPosition(this.computedDuration)

        return note
    }

    createNoteFromExisting(note: PassageNote) {
        if (note.removed) {
            return
        }

        const newNote = this.createNote(note.position)
        const copy = note.copy()
        copy._id = newNote._id
        copy.items = []
        return copy
    }

    get resolvedNotes() {
        return this.notes.filter((note) => note.resolved).sort((a, b) => a.position - b.position)
    }

    get unresolvedNotes() {
        return this.notes.filter((note) => !note.resolved).sort((a, b) => a.position - b.position)
    }

    async setStatus(status: string) {
        log('setStatus', status)
        if (status === this.status) {
            return
        }

        const doc = this._toDocument({})
        doc.status = status
        await this.db.put(doc)
    }

    async addNote(note: PassageNote): Promise<PassageVideo> {
        const noteExists = this.notes.find((n) => note._id === n._id)
        if (noteExists) {
            return this
        }

        note.rank = DBObject.numberToRank(note.position)
        await this.db.put(note.toDocument())

        const _note = this.notes.find((n) => note._id === n._id)
        if (!_note) {
            throw Error('Could not find note we just created')
        }
        return this
    }

    async removeNote(_id: string) {
        await remove(this.notes, _id)
    }

    createSegment(position: number) {
        const newId = this.db.getNewId(this.segments, new Date(Date.now()), 'seg_')
        const pvsg = new PassageSegment(`${this._id}/${newId}`, this.db)
        pvsg.position = position
        pvsg.rank = DBObject.numberToRank(position)
        return pvsg
    }

    createSegmentFromExisting(segment: PassageSegment) {
        if (segment.removed) {
            return
        }

        const segmentCopy = this.createSegment(segment.position)
        const copy = segment.copy()
        copy._id = segmentCopy._id
        copy.labels = []
        copy.glosses = []
        copy.documents = []
        copy.audioClips = []

        return copy
    }

    async addSegmentFromExisting(segment: PassageSegment) {
        segment.rank = DBObject.numberToRank(segment.position)
        await this.db.put(segment.toDocument())
        const _segment = this.getAllBaseSegments().find((s) => s._id === segment._id)
        if (!_segment) {
            throw new Error('Could not find segment we just inserted')
        }
        return _segment
    }

    async addSegment({
        position,
        labels = [],
        references = [],
        cc = '',
        isHidden = false
    }: {
        position: number
        labels?: PassageSegmentLabel[]
        references?: RefRange[]
        cc?: string
        isHidden?: boolean
    }) {
        // If segment to be added is too close to an existing segment, ignore add
        const closeToStart = (ps: PassageSegment) =>
            ps.position + MIN_SEGMENT_LENGTH >= position && ps.position - MIN_SEGMENT_LENGTH <= position
        const closeToEnd = (ps: PassageSegment) =>
            ps.endPosition + MIN_SEGMENT_LENGTH >= position && ps.endPosition - MIN_SEGMENT_LENGTH <= position
        const closeTo = (ps: PassageSegment) => closeToStart(ps) || closeToEnd(ps)

        // If the new segment is very close to an existing segment, just use existing segment
        const existingSegments = this.getAllBaseSegments()
        let segmentIndex = existingSegments.findIndex(closeTo)
        if (segmentIndex >= 0) {
            return existingSegments[segmentIndex]
        }

        const pvsg = this.createSegment(position)
        pvsg.labels = labels
        pvsg.references = references
        pvsg.cc = cc
        pvsg.isHidden = isHidden

        // If there are no segments yet, the new segment always goes to end of video
        if (existingSegments.length === 0) {
            pvsg.endPosition = this.duration
            await this.db.put(pvsg.toDocument())
            const updatedSegments = this.getAllBaseSegments()
            segmentIndex = updatedSegments.findIndex((s) => s._id === pvsg._id)
            if (segmentIndex === -1) {
                throw Error('Could not find added segment')
            }
            return updatedSegments[segmentIndex]
        }

        const currentSegment = this.getAllBaseSegments()
            .slice()
            .reverse()
            .find((s) => s.position < position)
        if (!currentSegment) {
            throw Error(`could not find segment to insert into, position=${position}`)
        }

        pvsg.endPosition = currentSegment.endPosition
        await this.db.put(pvsg.toDocument())

        await currentSegment.setEndPosition(position, this)

        const updatedSegments = this.getAllBaseSegments()
        segmentIndex = updatedSegments.findIndex((s) => s._id === pvsg._id)
        if (segmentIndex === -1) throw Error('Could not find added segment')

        return updatedSegments[segmentIndex]
    }

    async removeSegment(_id: string) {
        const existingSegments = this.getAllBaseSegments()
        const index = existingSegments.findIndex((s) => s._id === _id)
        if (index < 0 || this.isPatch) {
            return
        }

        const segment = existingSegments[index]
        const previousIndex = index - 1
        if (previousIndex >= 0) {
            await segment.mergeMetadata(existingSegments[previousIndex])
        }

        await remove(existingSegments, _id)
        if (previousIndex >= 0) {
            await this.getAllBaseSegments()[previousIndex].setEndPosition(segment.endPosition, this)
        }
    }

    // create the segments for this selection
    async createSelectionSegment(passage: Passage, timeline: Timeline) {
        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()

        const startPosition = this.timeToPosition(passage, selectionStartTime)
        const endPosition = this.timeToPosition(passage, selectionEndTime)
        if (startPosition === undefined || endPosition === undefined) {
            throw Error('*Could not determine selection position')
        }

        await this.addSegment({ position: endPosition })
        const segment = await this.addSegment({ position: startPosition })
        return segment
    }

    // Return true if a patch could be created from startTime to endTime on passageVideo.
    patchable(timeline: Timeline, _displayError?: (message: string) => void) {
        const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
        const startIndex = this.timeToSegmentIndex(selectionStartTime)
        const endIndex = this.timeToSegmentIndex(selectionEndTime)

        if (startIndex === -1 || endIndex === -1) {
            if (_displayError) _displayError(t('Something went wrong - could not find selection.'))
            return false
        }

        if (startIndex !== endIndex) {
            if (_displayError) _displayError(t('Selection must not include more than one segment.'))
            return false
        }

        if (this.getAllBaseSegments()[startIndex].isPatched) {
            if (_displayError)
                _displayError(t('Cannot patch a selection in a patched segment. Delete the existing patches first.'))
            return false
        }

        return true
    }

    /* Common Marker code */
    async addMarkerFromExisting(marker: Marker, useExistingModDate?: boolean) {
        if (marker instanceof ReferenceMarker) {
            marker.rank = DBObject.numberToRank(marker.position)
            return this.db.put(marker.toDocument(useExistingModDate))
        }

        if (marker instanceof BiblicalTermMarker) {
            return this.db.put(marker.toDocument(useExistingModDate))
        }

        throw Error('Could not addMarkerFromExisting', marker)
    }

    createMarkerFromExisting(marker: Marker) {
        if (marker.removed) {
            return
        }

        const newMarker =
            marker instanceof ReferenceMarker
                ? this.createReference(marker.position)
                : this.createBiblicalTermMarker(new Date(marker.creationDate))

        const copy = marker.copy()
        copy._id = newMarker._id
        return copy
    }

    async copyMarkersFromSingleSegment(oldSegment: PassageSegment, markers: Marker[]) {
        if (!this.getAllBaseSegments().length) {
            return Promise.resolve([])
        }

        const copiedMarkers: Marker[] = []
        for (const marker of markers) {
            const copy = this.createMarkerFromExisting(marker)
            if (copy) {
                copy.creationDate = marker.creationDate
                copy.modDate = marker.modDate
                copy.position = marker.time - oldSegment.time // adjust copied marker positions so they are offset from the old segment
                copiedMarkers.push(copy)
            }
        }

        const updatedMarkers = PassageVideoMarker.adjustMarkerPositions(copiedMarkers, this.duration) as Marker[]
        return Promise.all(updatedMarkers.map((marker) => this.addMarkerFromExisting(marker, true)))
    }

    // Copy markers from an old video to the current video. Assume there are no patches in the current video.
    // Only copy markers that will fit within the current video.
    async copyMarkers(
        oldVideo: PassageVideo,
        passage: Passage,
        markerType: PassageVideoMarkerType,
        useExistingModDate?: boolean
    ) {
        const copiedMarkers: Marker[] = []

        const startTime = 0
        const endTime = this.duration
        const visibleMarkers = oldVideo
            .getVisibleMarkers(passage, markerType)
            .filter((marker) => marker.time >= startTime && marker.time <= endTime)

        for (const marker of visibleMarkers) {
            const copy = this.createMarkerFromExisting(marker as Marker)
            if (copy) {
                copy.creationDate = marker.creationDate
                if (useExistingModDate) {
                    copy.modDate = marker.modDate
                }

                // there are no patches in this recording, so the time is the same as the position
                copy.position = marker.time
                copiedMarkers.push(copy)
            }
        }

        const updatedMarkers = PassageVideoMarker.adjustMarkerPositions(copiedMarkers, this.duration) as Marker[]
        return Promise.all(updatedMarkers.map((marker) => this.addMarkerFromExisting(marker, useExistingModDate)))
    }

    /* Gloss code. Deprecate? */
    createGloss() {
        const newId = this.db.getNewId(this.glosses, new Date(Date.now()), 'gls_')
        return new PassageGloss(`${this._id}/${newId}`, this.db)
    }

    createGlossFromExisting(gloss: PassageGloss) {
        if (gloss.removed) {
            return
        }

        const pg = this.createGloss()
        const copy = gloss.copy()
        copy._id = pg._id
        return copy
    }

    async addGlossFromExisting(gloss: PassageGloss) {
        gloss.rank = DBObject.numberToRank(gloss.position)
        await this.db.put(gloss.toDocument())
        if (!this.glosses.find((g) => g._id === gloss._id)) {
            log('new gloss not found', gloss._id)
        }
    }

    async addGloss(position: number, text: string) {
        // If segment to be added is within .1 second of an existing segment, ignore add
        const tolerance = 0.15
        const gap = (pg: PassageGloss) => isCloseEnough(pg.position, position, tolerance)
        if (this.glosses.find(gap)) {
            log(`addGloss reject gap=${gap}`)
            return
        }

        const pg = this.createGloss()
        pg.position = position
        pg.rank = DBObject.numberToRank(position)
        pg.text = text

        log('addGloss', pg)
        await this.db.put(pg.toDocument())
    }

    // Used from command line to copy glosses for testing
    async copyGlosses(glosses: PassageGloss[]) {
        for (const gloss of glosses) {
            const { position, text } = gloss
            if (position <= this.duration) {
                await this.addGloss(position, text)
            }
        }
    }

    async removeGloss(_id: string) {
        await remove(this.glosses, _id)
    }

    /* Biblical Term code */
    createBiblicalTermMarker(creationDate?: Date) {
        const date = creationDate ?? new Date(Date.now())
        const newId = this.db.getNewId(this.biblicalTermMarkers, date, 'btm_')
        return new BiblicalTermMarker(`${this._id}/${newId}`, this.db, date)
    }

    async addBiblicalTermMarker(targetGlossId: string, position: number) {
        const tolerance = 0.2
        const gap = (ktm: BiblicalTermMarker) => isCloseEnough(ktm.position, position, tolerance)
        if (this.biblicalTermMarkers.find(gap)) {
            log(`addBiblicalTermMarker reject gap=${tolerance}`)
            return undefined
        }

        const marker = this.createBiblicalTermMarker()
        marker.targetGlossId = targetGlossId
        marker.position = position

        const doc = marker.toDocument()
        this.db.submitChange(doc)

        return this.biblicalTermMarkers.find((ktm) => ktm._id === marker._id)
    }

    async removeBiblicalTermMarker(_id: string) {
        const idx = _.findIndex(this.biblicalTermMarkers, { _id })
        if (idx < 0) return

        const item = this.biblicalTermMarkers[idx]

        const doc = item._toDocument({})
        doc.removed = true
        this.db.submitChange(doc)
    }

    /* Verse Reference Marker code */
    createReference(position: number) {
        const newId = this.db.getNewId(this.references, new Date(Date.now()), 'ref_')
        const ref = new ReferenceMarker(`${this._id}/${newId}`, this.db)
        ref.position = position
        ref.rank = DBObject.numberToRank(position)
        return ref
    }

    async addNextReferenceInSequence(portion: Portion, passage: Passage, currentTime: number, versification: string) {
        const video = this.timeToVideo(passage, currentTime)
        const position = video.timeToPosition(passage, currentTime)
        const baseVideo = this.baseVideo(passage) || this
        const refRanges = baseVideo.getRefRanges(passage, currentTime)
        const defaultVerse = '001001001'
        let references = [new RefRange(defaultVerse, defaultVerse)]
        if (refRanges.length) {
            const previousReference = refRanges[refRanges.length - 1]
            const nextVerse = RefRange.nextVerse(previousReference.endRef, versification)
            references = [new RefRange(nextVerse, nextVerse)]
        } else if (passage.references.length) {
            references = passage.references
        } else if (portion.references.length) {
            references = portion.references
        }
        const reference = await video.addReference(references, position)
        return reference
    }

    async addReference(reference: RefRange[], position: number) {
        const gap = (ref: ReferenceMarker) => isCloseEnough(ref.position, position, ReferenceMarker.minDistance)
        if (this.references.find(gap)) {
            log(`addReference reject gap=${ReferenceMarker.minDistance}`)
            return undefined
        }

        const ref = this.createReference(position)
        ref.references = reference

        log('addReference', JSON.stringify(reference))
        const doc = ref.toDocument()
        this.db.submitChange(doc)

        // Make sure we have latest "reference" to ref
        const _ref = this.references.find((r) => r._id === ref._id)
        if (_ref) {
            _ref.newlyCreated = true
        }

        return _ref
    }

    async saveMarkerPosition(passage: Passage, time: number, marker: ReferenceMarker | BiblicalTermMarker) {
        return marker instanceof ReferenceMarker
            ? this.saveReferencePosition(passage, time, marker)
            : this.saveBiblicalTermMarkerPosition(passage, time, marker)
    }

    // Set the position of a verse reference, handling the case where the reference
    // is moved to another video within the same base video.
    async saveReferencePosition(passage: Passage, time: number, reference: ReferenceMarker) {
        const exists = passage.videos.some((vid) => vid.references.find((ref) => ref._id === reference._id))
        const oldVideo = passage.findVideo(reference._id)
        if (!exists || !oldVideo) {
            return
        }

        const baseVideo = this.baseVideo(passage) || this
        const video = baseVideo.timeToVideo(passage, time)
        const position = video.timeToPosition(passage, time)

        let _reference: ReferenceMarker | undefined
        if (video._id !== oldVideo._id) {
            await oldVideo.removeReference(reference._id)
            _reference = await video.addReference(reference.references, position)
        } else {
            await reference.setPosition(position)
            _reference = video.references.find((ref) => ref._id === reference._id)
        }

        return _reference
    }

    async saveBiblicalTermMarkerPosition(passage: Passage, time: number, marker: BiblicalTermMarker) {
        const exists = passage.videos.some((vid) => vid.biblicalTermMarkers.find((ktm) => ktm._id === marker._id))
        const oldVideo = passage.findVideo(marker._id)
        if (!exists || !oldVideo) {
            return
        }

        const baseVideo = this.baseVideo(passage) || this
        const video = baseVideo.timeToVideo(passage, time)
        const position = video.timeToPosition(passage, time)

        let _marker: BiblicalTermMarker | undefined
        if (video._id !== oldVideo._id) {
            await oldVideo.removeBiblicalTermMarker(marker._id)
            _marker = await video.addBiblicalTermMarker(marker.targetGlossId, position)
        } else {
            await marker.setPosition(position)
            _marker = video.biblicalTermMarkers.find((ktm) => ktm._id === marker._id)
        }

        return _marker
    }

    async removeReference(_id: string) {
        const idx = _.findIndex(this.references, { _id })
        if (idx < 0) return

        const item = this.references[idx]

        const doc = item._toDocument({})
        doc.removed = true
        this.db.submitChange(doc)
    }

    createHighlight() {
        const newId = this.db.getNewId(this.glosses, new Date(Date.now()), 'hgh_')
        const ph = new PassageHighlight(`${this._id}/${newId}`, this.db)
        return ph
    }

    createHighlightFromExisting(highlight: PassageHighlight) {
        if (highlight.removed) {
            return
        }

        const newHighlight = this.createHighlight()
        const copy = highlight.copy()
        copy._id = newHighlight._id
        return copy
    }

    async addHighlightFromExisting(highlight: PassageHighlight) {
        await this.db.put(highlight.toDocument())
        if (!this.highlights.find((h) => h._id === highlight._id)) {
            log('new highlight not found', highlight._id)
        }
    }

    async addHighlight(color: number, firstId: string, lastId: string, resourceName: string) {
        // If the user selected the words by dragging right to left first and last will
        // be reversed, switch them
        if (firstId > lastId) {
            const temp = lastId
            lastId = firstId
            firstId = temp
        }

        if (color === 0) {
            // if this is a clear color request, seach throug all existing non clear color
            // highlights for this resourceName. If you don't find one that overlaps with
            // this range is nothing to clear, just return
            // Two ranges overlap if end1 >= start2 && end2 >= start1
            if (
                !this.highlights.find(
                    (h) => h.resourceName === resourceName && h.color && lastId >= h.firstId && h.lastId >= firstId
                )
            ) {
                log('addHighligh no-action-necessary')
                return
            }
        }

        const ph = this.createHighlight()
        ph.color = color
        ph.firstId = firstId
        ph.lastId = lastId
        ph.resourceName = resourceName

        await this.db.put(ph.toDocument())
    }

    // Convert specified time to a position offset in the segment containing that time
    timeToPosition(passage: Passage, time: number) {
        let segment: PassageSegment

        if (this.isPatch) {
            // Patch videos only have one segment
            segment = this.getAllBaseSegments()[0]
        } else {
            // Otherwise search for the segment by time
            segment = this.timeToSegment(time)
            segment = segment.actualSegment(passage)
        }

        log('!!!', time, segment.time, segment.position, segment.endPosition)

        return limit(segment.position + time - segment.time, segment.position, segment.endPosition)
    }

    // Return videoPassage that plays at this time.
    // Either a manin video or a patch video (if the segment has been patched)
    timeToVideo(passage: Passage, time: number) {
        const segment = this.timeToSegment(time)
        return segment.patchVideo(passage) || this
    }

    getAllBaseSegments() {
        return this.segments
    }

    getVisibleBaseSegments() {
        return this.segments.filter((segment) => segment.isVisible())
    }

    visibleSegments(passage: Passage) {
        return this.getVisibleBaseSegments().map((segment) => segment.actualSegment(passage))
    }

    timeToSegment(time: number) {
        const si = this.timeToSegmentIndex(time)
        return this.getAllBaseSegments()[si]
    }

    timeToSegmentIndex(time: number) {
        const existingSegments = this.getAllBaseSegments()
        if (existingSegments.length === 0) {
            throw Error('no segments present')
        }

        // Find next segment with a time greater than this time
        let index = existingSegments.findIndex((s) => s.time > time)
        if (index === -1) {
            // If there is not segment with a position greater than this position
            // default to last segment
            index = existingSegments.length - 1
        } else if (index > 0) {
            // If not the fist segment, use the segment before this
            index -= 1
        }

        return getPreviousNonHiddenSegmentIndex(existingSegments, index)
    }

    // returns { segment, segmentIndex }
    positionToSegment(position: number) {
        const existingSegments = this.getAllBaseSegments()
        if (existingSegments.length === 0) {
            throw Error('no segments present')
        }

        let segmentIndex = existingSegments.findIndex((s) => s.position > position)
        if (segmentIndex === -1) {
            // If there is not segment with a position greater than this position
            // default to last segment
            segmentIndex = existingSegments.length - 1
        } else if (segmentIndex > 0) {
            // If not the fist segment, use the segment before this
            segmentIndex -= 1
        }

        const i = getPreviousNonHiddenSegmentIndex(existingSegments, segmentIndex)
        const segment = existingSegments[i]
        return { segment, segmentIndex: i }
    }

    // ??? this is using time for the base segment, not actual segment, is that OK???
    positionToTime(position: number) {
        const { segment } = this.positionToSegment(position)
        return segment.positionToTime(position)
    }

    // For a patch video find the video it is a patch of
    baseVideo(passage: Passage, dontLogError?: boolean) {
        if (!this.isPatch) return null

        for (const video of passage.videos) {
            if (video.getAllBaseSegments().some((segment) => segment.videoPatchHistory.includes(this._id))) {
                return video
            }
        }

        if (this.removed) return null

        // Should we be throwing an exception here?
        // There is not much we can do about it.
        if (!dontLogError) {
            log(
                '### cannot find baseVideo for patch',
                fmt({
                    passage: passage.name,
                    id: `_${this._id}`,
                    creationDate: this.creationDate
                })
            )
        }

        return null
    }

    log(passage: Passage | null, label: string) {
        log(`[PassageVideo] ${label}`, JSON.stringify(this.dbg(passage, 's'), null, 4))
    }

    log2(tag: string, values: any) {
        const _id = this._id.split('/').slice(-1)[0]
        log(`PassageVideo[${_id}] ${tag}`, JSON.stringify(values, null, 4))
    }

    // Set the starting time in the main video timeline for each segment.
    setSegmentTimes(passage: Passage, forceReset?: boolean) {
        if (!forceReset && this.segmentTimesSet) return

        let time = 0

        if (this.isPatch) {
            // should not be calling this function for a patch segment since patch segment
            // times should be set by calling this function on the containing video
            console.error('setSegmentTimes called on patch segment!!!')
            return
        }

        this.getVisibleBaseSegments().forEach((segment) => {
            let { duration } = segment
            segment.time = time

            // If there are patch video on this segment set all of them to start at the current
            // time. Save the duration of the last (onTop) segment as the duration of this
            // segment.
            for (const patchId of segment.videoPatchHistory) {
                const video = passage.findVideo(patchId)
                if (!video) {
                    log('setSegmentTimes no video!')
                    continue // should never happen
                }

                const firstSegment = video.getAllBaseSegments()[0]
                firstSegment.time = time
                duration = firstSegment.duration
            }

            time += duration
        })

        this.computedDuration = time // set duration based on total duration of segments
        // this.log2(`setSegmentTimes[${time.toFixed(2)}]`,
        //     this.segments.map(s => ({time: s.time.toFixed(2), duration: s.duration.toFixed(2)})))

        this.segmentTimesSet = true
    }

    // For all notes in this video (including notes on patches)
    // set the note time and segmentIndex.
    private setNoteTimes(passage: Passage) {
        for (const note of this.notes) {
            if (this.getAllBaseSegments().length === 0) {
                // 2020-06-09 there is a bug that is causing video to have not segments
                // ignore these video for now
                log(`### video has no segments ${this._id}`)
                continue
            }

            const { segment } = this.positionToSegment(note.position)

            const time = segment.positionToTime(note.position)

            const actualSegment = segment.actualSegment(passage)
            const actualSegmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

            note.time = Math.min(time, actualSegmentEndTime)

            note.onTop = !segment.isPatched
        }

        for (const { patchVideo, onTop, actualSegment } of this.patchVideos(passage)) {
            patchVideo.setupNotesForPatch(actualSegment, onTop)
        }
    }

    private setMarkerTimes(passage: Passage, markers: PassageVideoMarker[], markerType: PassageVideoMarkerType) {
        for (const marker of markers) {
            if (this.getAllBaseSegments().length === 0) {
                log(`### video has no segments ${this._id}`)
                continue
            }

            const { segment, segmentIndex } = this.positionToSegment(marker.position)
            const time = segment.positionToTime(marker.position)

            const actualSegment = segment.actualSegment(passage)
            const actualSegmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

            marker.time = Math.min(time, actualSegmentEndTime)
            marker.segmentIndex = segmentIndex
        }

        for (const { patchVideo, actualSegment, segmentIndex } of this.patchVideos(passage)) {
            const patchVideoMarkers =
                markerType === 'reference' ? patchVideo.references : patchVideo.biblicalTermMarkers
            patchVideo.setupMarkersForPatch(actualSegment, segmentIndex, patchVideoMarkers)
        }
    }

    private setupMarkersForPatch(actualSegment: PassageSegment, segmentIndex: number, markers: PassageVideoMarker[]) {
        // Limit start and end times to match the top most (aka 'actual') patch

        const startTime = actualSegment.time
        const endTime = actualSegment.time + actualSegment.duration

        const segment = this.getAllBaseSegments()[0]

        markers.forEach((marker) => {
            const time = segment.positionToTime(marker.position)
            marker.time = limit(time, startTime, endTime)
            marker.segmentIndex = segmentIndex
        })
    }

    // Iterate over all videos which are patches to this base (i.e. !isPatch) video.
    // Ignores (with ###log message) any videos listed as patches in videoPatchHistory
    // but not actually found in passage
    *patchVideos(passage: Passage, options?: { onlyOnTop?: boolean }) {
        const { onlyOnTop } = options ?? {}
        let nonHiddenSegmentIndex = 0
        const segments = this.getAllBaseSegments()
        for (let segmentIndex = 0; segmentIndex < segments.length; ++segmentIndex) {
            const segment = segments[segmentIndex]
            const actualSegment = segment.actualSegment(passage)
            const { videoPatchHistory } = segment
            for (let i = 0; i < videoPatchHistory.length; ++i) {
                const videoId = videoPatchHistory[i]
                const patchVideo = passage.findVideo(videoId)
                if (!patchVideo) continue // ignore missing patch video

                const onTop = i === videoPatchHistory.length - 1

                if (onlyOnTop && !onTop) {
                    continue
                }

                yield {
                    patchVideo,
                    nonHiddenSegmentIndex,
                    segmentIndex,
                    actualSegment,
                    onTop
                }
            }

            if (segment.isVisible()) {
                nonHiddenSegmentIndex += 1
            }
        }
    }

    // Setup time and onTop attributes for all notes on this patch
    private setupNotesForPatch(actualSegment: PassageSegment, onTop: boolean) {
        // Limit start and end times to match the top most (aka 'actual') patch (since it

        const startTime = actualSegment.time
        const endTime = actualSegment.time + actualSegment.duration

        const segment = this.getAllBaseSegments()[0]

        this.notes.forEach((note) => {
            const time = segment.positionToTime(note.position)
            note.time = limit(time, startTime, endTime)
            note.onTop = onTop
        })
    }

    // Find the most recent unviewed note. Unviewed notes are those created after
    // the specified cutoff date that are unviewed.
    firstUnviewedNoteAfterDate(passage: Passage, username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const visibleNotes = this.getVisibleNotes(passage, includeConsultantOnlyNotes)
        let unviewedItems = visibleNotes.flatMap((note) =>
            note.unviewedItemsAfterDate(username, cutoff, includeConsultantOnlyNotes)
        )
        unviewedItems = _.sortBy(unviewedItems, 'creationDate')
        const newestUnviewedItem = unviewedItems[unviewedItems.length - 1]
        return newestUnviewedItem ? passage.findNote(newestUnviewedItem._id) || null : null
    }

    // Find the most recent unresolved note. Unresolved notes are those created after
    // the specified cutoff date that are unresolved.
    mostRecentUnresolvedNote(passage: Passage, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const visibleNotes = this.getVisibleNotes(passage, includeConsultantOnlyNotes)
        let unresolvedItems = visibleNotes
            .filter((note) => !note.resolved)
            .filter((note) => includeConsultantOnlyNotes || !note.consultantOnly)
            .flatMap((note) => note.items)
            .filter((item) => newerThanCutoffDate(item.creationDate, cutoff))
        unresolvedItems = _.sortBy(unresolvedItems, 'creationDate')
        const newestUnresolvedItem = unresolvedItems[unresolvedItems.length - 1]
        return newestUnresolvedItem ? passage.findNote(newestUnresolvedItem._id) || null : null
    }

    isUnviewedAfterDate(username: string, cutoff: Date) {
        const newerThanCutoff = newerThanCutoffDate(this.creationDate, cutoff)
        const isCreator = this.creator === username
        const hasViewed = this.viewedBy.includes(username)
        return newerThanCutoff && !isCreator && !hasViewed
    }

    // Return a a list of all the notes for this video, sorted by ascending time
    // ??? does this need to deal with resolved status?
    getVisibleNotes(passage: Passage, showConsultantOnlyNotes: boolean) {
        if (!this.verifyIsBaseVideo()) return []

        // There is no need to filter out markers from hidden segments, because users are
        // unable to make markers in those segments.
        let notes = [...this.notes]
        for (const { patchVideo } of this.patchVideos(passage)) {
            notes = notes.concat(patchVideo.notes)
        }

        this.setSegmentTimes(passage) // must be called before setNoteTimes
        this.setNoteTimes(passage)

        notes = notes.filter((note) => showConsultantOnlyNotes || !note.consultantOnly)

        notes = _.sortBy(notes, 'time')
        // this.log('getVisibleNotes', {_: notes.map(note => note.time.toFixed(2))})

        return notes
    }

    verifyIsBaseVideo() {
        if (this.isPatch) {
            log(`### Is not base video ${this._id}`)
            return false
        }

        return true
    }

    // Return a a list of all the notes for this video with x/y/width values set
    // forceSmallMarkers is only used for unit testing
    getDrawableNotes(
        passage: Passage,
        noteBarWidth: number,
        showConsultantOnlyNotes: boolean,
        forceSmallMarkers?: boolean
    ) {
        const notes = this.getVisibleNotes(passage, showConsultantOnlyNotes)

        const useSmallMarkers = forceSmallMarkers || notes.length > 12

        const markerWidth = useSmallMarkers ? smallNoteMarker : largeNoteMarker
        notes.forEach((note) => (note.width = markerWidth))

        // Setup the x, y position for each note to make it not overlap other notes in the time line
        notes.forEach((note, index) => note.setupMarker(notes, index, this.computedDuration, noteBarWidth))

        this.log2('getDrawableNotes', {
            noteBarWidth,
            duration: this.computedDuration,
            _: notes.map((note) => note.x.toFixed(0))
        })

        return notes
    }

    getVisibleMarkers(passage: Passage, markerType: PassageVideoMarkerType) {
        if (!this.verifyIsBaseVideo()) return []

        let markers: PassageVideoMarker[] = []

        // There is no need to filter out markers from hidden segments, because users are
        // unable to make markers in those segments.
        const passageVideoMarkers = markerType === 'reference' ? this.references : this.biblicalTermMarkers
        for (const marker of passageVideoMarkers) {
            const { segment } = this.positionToSegment(marker.position)
            if (!segment.isPatched) {
                markers.push(marker)
            }
        }

        for (const { patchVideo } of this.patchVideos(passage, { onlyOnTop: true })) {
            const patchVideoMarkers =
                markerType === 'reference' ? patchVideo.references : patchVideo.biblicalTermMarkers
            markers = markers.concat(patchVideoMarkers)
        }

        this.setSegmentTimes(passage) // must be called before setReferenceTimes
        this.setMarkerTimes(passage, passageVideoMarkers, markerType)

        markers = _.sortBy(markers, 'time')
        return markers
    }

    getVisibleBiblicalTermMarkers(passage: Passage) {
        return this.getVisibleMarkers(passage, 'biblicalTerm') as BiblicalTermMarker[]
    }

    getVisibleReferenceMarkers(passage: Passage) {
        return this.getVisibleMarkers(passage, 'reference') as ReferenceMarker[]
    }

    // Return a a list of all the visible glosses for this base video, sorted by ascending time
    getVisibleGlosses(passage: Passage) {
        if (!this.verifyIsBaseVideo()) return []

        let glosses: PassageGloss[] = []
        this.setSegmentTimes(passage) // ensure segment times up to date

        // There is no need to filter out markers from hidden segments, because users are
        // unable to make markers in those segments.
        for (const gloss of this.glosses) {
            const { segment } = this.positionToSegment(gloss.position)
            // A gloss on the base video is visible if it starts in an unpatched segment
            if (!segment.isPatched) {
                gloss.time = segment.positionToTime(gloss.position)
                glosses.push(gloss)
            }
        }

        // A gloss on a patch video is visible if the patch is visible
        // if patch is not visible, skip its glosses
        for (const { patchVideo } of this.patchVideos(passage, { onlyOnTop: true })) {
            const segment = patchVideo.getAllBaseSegments()[0] // a patch has exactly one segment

            for (const gloss of patchVideo.glosses) {
                gloss.time = segment.positionToTime(gloss.position)
                glosses.push(gloss)
            }
        }

        glosses = _.sortBy(glosses, 'time')

        return glosses
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        const { creationDate, version } = this
        const date = new Date(creationDate)
        const base = dateFormatter.format(date)
        const ending = version > 0 ? `-${version}` : ''
        return `${base}${ending}`
    }

    findSegmentIndex(_id: string /* segment _id */, throwIfMissing?: boolean) {
        const segmentIndex = this.getAllBaseSegments().findIndex((s) => s._id === _id)

        if (throwIfMissing && segmentIndex < 0) throw Error('Something went wrong. Could not find segment.')

        return segmentIndex
    }

    // Get the first non-empty reference that starts before the specified time
    getRefRanges(passage: Passage, currentTime: number) {
        const visibleReferences = this.getVisibleReferenceMarkers(passage)

        let references: RefRange[] = []
        for (const ref of visibleReferences) {
            if (ref.time > currentTime) {
                break
            }
            if (ref.references.length > 0) {
                references = ref.references
            }
        }

        return references
    }

    // comments
    // logging

    // create minimum number of slices of base video and latest patches that don't
    // have gaps between them
    createSlicesWithNoGaps(
        passage: Passage,
        selectionStartTime: number, // -1 if no selection in effect
        selectionEndTime: number // -1 if no selection in effect
    ) {
        const baseVideo = this.baseVideo(passage) || this
        const visibleSegments = baseVideo.getVisibleBaseSegments()
        const newSlices: VideoSlice[] = []

        for (const segment of visibleSegments) {
            const actualVideo = segment.actualVideo(passage) || baseVideo
            const actualSegment = segment.actualSegment(passage)
            let { position, endPosition } = actualSegment

            const { time } = actualSegment
            const endTime = actualSegment.time + actualSegment.endPosition - actualSegment.position
            log('createSlicesWithNoGaps', fmt({ actualVideo, position, endPosition, time, endTime }))

            // Skip zero length segments. This could have happened in older recordings.
            if (endPosition - position <= 0) {
                log('Found a zero length segment, so skipping...')
                continue
            }
            // If there is a selection ...
            // If this segment ends before the selection starts, skip the segment
            // If this segment starts before the selection starts, move the start position of
            // the segment forward.
            if (selectionStartTime > 0) {
                if (endTime <= selectionStartTime) continue
                if (time < selectionStartTime) {
                    position = position + selectionStartTime - time
                    log('trim position', fmt({ position }))
                }
            }

            // If there is a selection ...
            // If this segment starts after the selection ends, skip the segment
            // If this segment ends after the selection ends, move the end position of
            // the segment backward.
            if (selectionEndTime > 0) {
                if (time >= selectionEndTime) continue
                if (endTime > selectionEndTime) {
                    endPosition -= endTime - selectionEndTime
                    log('trim endPosition', fmt({ endPosition }))
                }
            }

            // If this segment is contiguous with the previous segment, adjust the end position of the
            // last slice
            const lastSlice = newSlices.slice(-1)[0] // undefined if no new slices created yet
            if (this.isContiguousVideo(lastSlice?.video._id, lastSlice?.endPosition, actualVideo._id, position)) {
                log(`adjust endPosition ${lastSlice.endPosition} = ${endPosition}`)
                lastSlice.endPosition = endPosition
            } else {
                const newSlice: VideoSlice = { video: actualVideo, position, endPosition, src: '' }
                log('newSlice', fmt(newSlice))
                newSlices.push(newSlice)
            }
        }

        return newSlices
    }

    createSlices(passage: Passage) {
        const baseVideo = this.baseVideo(passage) || this
        const visibleSegments = baseVideo.visibleSegments(passage)
        const slices = []
        for (const segment of visibleSegments) {
            const actualVideo = segment.actualVideo(passage)
            if (!actualVideo) {
                throw new Error('No video for this segment')
            }
            const actualSegment = segment.actualSegment(passage)
            const { position, endPosition } = actualSegment
            slices.push(new VideoSlice(actualVideo, position, endPosition, ''))
        }

        return slices
    }

    private isContiguousVideo(
        videoId1: string | undefined,
        endPosition: number | undefined,
        videoId2: string,
        position: number
    ) {
        if (videoId1 !== videoId2) return false

        const aLittleBit = 0.05
        return isCloseEnough(endPosition ?? -1, position, aLittleBit)
    }

    async getPlayableSlices(passage: Passage, vvc: ViewableVideoCollection) {
        try {
            const baseVideo = this.baseVideo(passage) || this
            vvc.setup(passage, baseVideo)
            await vvc.download()
            await vvc.waitUntilDownloaded()
            const slices = baseVideo.createSlices(passage)
            const mediaSlices: MediaSlice[] = []
            for (const slice of slices) {
                const viewableVideo = vvc.viewableVideos.find((vv) => vv.video._id === slice.video._id)
                if (!viewableVideo) {
                    throw new Error('No viewable video for this slice')
                }
                mediaSlices.push(new MediaSlice(slice.position, slice.endPosition, viewableVideo.src))
            }

            return mediaSlices
        } catch (error) {
            return []
        }
    }

    getVisibleRefRanges(passage: Passage) {
        const references = this.getVisibleReferenceMarkers(passage)
        return references.flatMap((pvr) => pvr.references)
    }

    // Passage highlights are only stored on the base video, so there is no need to
    // look at any patch videos.
    getVisibleHighlights() {
        return this.highlights
    }

    isAudioOnly() {
        if (this.url.trim() === '') {
            return false
        }

        return this.mimeType.startsWith('audio')
    }

    private generateReferenceLabel({
        project,
        references,
        lastChapterId,
        exportTextFormat
    }: {
        project: Project
        references: RefRange[]
        lastChapterId: string
        exportTextFormat?: ExportTextFormat
        includeHeader?: boolean
    }) {
        if (!references.length) {
            return ''
        }

        if (exportTextFormat === ExportTextFormat.USFM) {
            const { startRef, endRef } = refRangesMinMax(references)
            return refRangeToUsfmLabel({ startRef, endRef, lastChapterId })
        }

        return `|${refRangesToDisplay(references, project)}|`
    }

    private getText({
        passage,
        project,
        usePatchedSegments,
        getSegmentText,
        exportTextFormat,
        includeHeader,
        previousChapterId
    }: ExportTextParams & {
        usePatchedSegments: boolean
        getSegmentText: (segment: PassageSegment, visibleSegmentIndex: number) => { text: string; modDate: string }
    }) {
        const segments = usePatchedSegments
            ? this.getAllBaseSegments().map((segment) => segment.actualSegment(passage))
            : this.getAllBaseSegments()
        const visibleReferences = this.getVisibleReferenceMarkers(passage)
        const hasVisibleReferences = visibleReferences.length > 0

        let header = ''
        if (includeHeader && exportTextFormat === ExportTextFormat.USFM) {
            const { startRef } = refRangesMinMax(passage.defaultReferences)
            header = refToUsfmBookHeader(startRef, project)
        }

        let lastChapterId = previousChapterId ?? ''
        if (!hasVisibleReferences) {
            const references = passage.references
            const referenceHeader = this.generateReferenceLabel({
                project,
                references,
                lastChapterId: previousChapterId ?? '',
                exportTextFormat
            })
            header = `${header}${referenceHeader}`

            const { endRef } = refRangesMinMax(references)
            lastChapterId = endRef ? refToChapterId(endRef) : lastChapterId
        }

        let visibleSegmentIndex = 0
        return segments.reduce(
            (prev, segment, segmentIndex) => {
                if (!segment.isVisible()) {
                    return prev
                }

                const references = visibleReferences
                    .filter((ref) => ref.segmentIndex === segmentIndex)
                    .flatMap((marker) => marker.references)

                const referenceLabel = this.generateReferenceLabel({
                    project,
                    references,
                    lastChapterId: prev.lastChapterId,
                    exportTextFormat
                })

                const { endRef } = refRangesMinMax(references)

                const { text, modDate } = getSegmentText(segment, visibleSegmentIndex)

                visibleSegmentIndex += 1

                return {
                    text: referenceLabel
                        ? `${prev.text}${!prev.text || /[\n\r]$/.test(prev.text) ? '' : '\n'}${referenceLabel} ${text}`
                        : `${prev.text}${text && prev.text && /\S$/.test(prev.text) ? ' ' : ''}${text}`,
                    modDate: modDate > prev.modDate ? modDate : prev.modDate,
                    lastChapterId: endRef ? refToChapterId(endRef) : prev.lastChapterId
                }
            },

            { text: header, modDate: '', lastChapterId }
        )
    }

    segmentTextGetter(documentType: SegmentDocumentType, exportTextFormat?: ExportTextFormat) {
        return (segment: PassageSegment, visibleSegmentIndex: number) => {
            const { text, modDate = '' } = segment.getCurrentDocument(documentType) ?? { text: '', modDate: ' ' }
            const segmentText =
                exportTextFormat === ExportTextFormat.USFM ? text : `\n[Segment ${visibleSegmentIndex + 1}] ${text}`
            return { text: segmentText, modDate }
        }
    }

    // For exporting in USFM format, we need all passage references and be of the same book
    getBackTranslation({ passage, project, exportTextFormat, includeHeader, previousChapterId }: ExportTextParams) {
        return this.getText({
            passage,
            project,
            usePatchedSegments: true,
            getSegmentText: this.segmentTextGetter('backTranslationText', exportTextFormat),
            exportTextFormat,
            includeHeader,
            previousChapterId
        })
    }

    getTranscription({ passage, project, exportTextFormat, includeHeader, previousChapterId }: ExportTextParams) {
        return this.getText({
            passage,
            project,
            usePatchedSegments: true,
            getSegmentText: this.segmentTextGetter('transcription', exportTextFormat),
            exportTextFormat,
            includeHeader,
            previousChapterId
        })
    }

    getReviewRecordings(reviewProject: ReviewProject) {
        return reviewProject.passageRecordings.filter((rec) => rec.passageRecordingId === this._id)
    }

    getLatestReviewRecording(reviewProject: ReviewProject) {
        if (this.isPatch) {
            return
        }

        const existingReviews = this.getReviewRecordings(reviewProject)
        const sortedReviews = _.sortBy(existingReviews, 'creationDate')
        if (sortedReviews.length) {
            return sortedReviews[sortedReviews.length - 1]
        }
    }

    isInReview(reviewProject?: ReviewProject) {
        return reviewProject && this.getLatestReviewRecording(reviewProject)?.isActive
    }

    isAppendable() {
        if (!this.verifyIsBaseVideo()) {
            return false
        }

        const allSegments = this.getAllBaseSegments()
        if (allSegments.length === 0) {
            return false
        }

        const lastSegment = allSegments[allSegments.length - 1]
        return (
            !lastSegment.isVisible() && lastSegment.duration > 2 * MIN_SEGMENT_LENGTH // make sure the last segment stays long enough
        )
    }
}

import { observable, computed } from 'mobx'
import _ from 'underscore'

import { DBObject } from './DBObject'
import { createDocumentObject, addDocumentObject } from './DocumentUtils'
import { PassageDocument } from './PassageDocument'
import { PassageNote } from './PassageNote'
import { PassageSegment } from './PassageSegment'
import { PassageVideo } from './PassageVideo'
import { Project } from './Project'
import { remove } from './Utils'
import { HIDDEN_SEGMENT_LENGTH_SECONDS } from '../components/utils/Wav'
import { saveRecording } from '../components/video/VideoUploader'
import { RefRange } from '../resources/RefRange'
import { DbObjectIdPrefix, RecordingMediaType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

export enum PassageContentTypes {
    Translation = 'Translation',
    Introduction = 'Introduction',
    'Introduction+Translation' = 'Introduction+Translation',
    'Introduction+Translation+Other' = 'Introduction+Translation+Other',
    Nonpublishable = 'Nonpublishable',
    Other = 'Other'
}

export class Passage extends DBObject {
    @observable name = ''

    @observable difficulty = 1.0

    @observable rank = ''

    @observable assignee = ''

    @observable documents: PassageDocument[] = []

    @observable videos: PassageVideo[] = []

    @observable _rev = 0

    @observable compressionProgressMessage = '' // not persisted

    @observable copiedFromId = '' // passage was copied from another passage. e.g. <projectName>/<_id>

    @observable contentType: PassageContentTypes = PassageContentTypes.Translation

    @observable references: RefRange[] = []

    @observable recordingMediaType = RecordingMediaType.AUDIO

    @computed get trackedProjectName() {
        if (this.copiedFromId.indexOf('/') >= 0) {
            return this.copiedFromId.slice(0, this.copiedFromId.indexOf('/'))
        }
        return ''
    }

    @computed get trackedPassageId() {
        return this.copiedFromId.slice(this.copiedFromId.indexOf('/') + 1)
    }

    @computed get videoBeingCompressed() {
        return this.compressionProgressMessage.trim() !== ''
    }

    /**
     * The task (aka status) for this passage is the status of the most recent (undeleted) video
     * that has a status.
     */
    @computed get task() {
        const vnds = this.videosNotDeleted
        const i = _.findLastIndex(vnds, (v) => Boolean(v.status))
        return i < 0 ? '' : vnds[i].status
    }

    setCompressionProgressMessage(message: string) {
        this.compressionProgressMessage = message
    }

    toDocument() {
        const { name, rank, difficulty, copiedFromId, assignee, contentType, references, recordingMediaType } = this
        const serializedReferences = JSON.stringify(references)
        return this._toDocument({
            name,
            rank,
            difficulty,
            copiedFromId,
            assignee,
            contentType,
            references: serializedReferences,
            recordingMediaType
        })
    }

    toSnapshot() {
        const snapshot = this.toDocument()
        snapshot.videos = this.videos.map((video) => video.toSnapshot())

        return snapshot
    }

    async setAssignee(assignee: string) {
        if (this.assignee === assignee) {
            return
        }
        const doc = this._toDocument({ assignee, model: 7 })
        await this.db.put(doc)
    }

    async setReferences(references: RefRange[]) {
        const serializedReferences = JSON.stringify(references)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        log('setReferences', serializedReferences)
        const doc = this._toDocument({ references: serializedReferences })
        await this.db.put(doc)
    }

    // All unresolved notes for this passage ordered by increasing position
    get notes() {
        const notes: PassageNote[] = []

        this.videos.forEach((video) => {
            video.notes.filter((note) => !note.resolved).forEach((note) => notes.push(note))
        })

        return notes.sort((a, b) => a.position - b.position)
    }

    async setName(name: string) {
        if (name === this.name) return

        const doc = this._toDocument({ name })
        await this.db.put(doc)
    }

    async setRank(rankNumber: number) {
        const rank = DBObject.numberToRank(rankNumber)
        if (rank === this.rank) {
            return
        }
        const doc = this._toDocument({ rank })
        await this.db.put(doc)
    }

    createDocument(title: string) {
        const { db, documents, _id: parentId } = this
        const childId = db.getNewId(documents, new Date(Date.now()), DbObjectIdPrefix.PASSAGE_DOCUMENT)
        const id = `${parentId}/${childId}`
        return createDocumentObject({
            id,
            parent: this,
            title
        })
    }

    createDocumentFromExisting(passageDocument: PassageDocument) {
        const newDocument = this.createDocument('')
        const copy = passageDocument.copy()
        copy._id = newDocument._id
        return copy
    }

    async addDocument(passageDocument: PassageDocument) {
        return addDocumentObject({ parent: this, passageDocument })
    }

    async removeDocument(_id: string) {
        await remove(this.documents, _id)
    }

    recordAudioOnly() {
        return this.latestVideo?.isAudioOnly() ?? this.recordingMediaType === RecordingMediaType.AUDIO
    }

    createVideo(projectName: string, creationDate?: Date) {
        const date = creationDate ?? new Date(Date.now())
        const dateId = this.db.getNewId(this.videos, date)
        const itemId = `${this._id}/${dateId}`
        const video = new PassageVideo(itemId, this.db, creationDate)

        video.url = `${projectName}/${itemId}`

        return video
    }

    createVideoFromExisting(passageVideo: PassageVideo) {
        // we don't check if the video has been removed. Unlike other objects, removed passage
        // videos stay around in the model.
        const video = this.createVideo('', new Date(passageVideo.creationDate))
        const copy = passageVideo.copy()
        copy._id = video._id
        copy.segments = []
        copy.notes = []
        copy.glosses = []
        copy.highlights = []
        return copy
    }

    copy() {
        let copy = new Passage(this._id, this.db)
        copy = Object.assign(copy, this)
        copy.documents = this.documents.map((doc) => doc.copy())
        copy.references = this.references.map((ref) => ref.copy())
        copy.videos = this.videos.map((video) => video.copy())
        return copy
    }

    async addVideoFromExisting({
        video,
        project,
        videoToCopyFrom,
        options
    }: {
        video: PassageVideo
        project: Project
        videoToCopyFrom: PassageVideo
        options?: { preserveSegmentation?: boolean; warnAboutOldData?: boolean }
    }) {
        const { preserveSegmentation, warnAboutOldData } = options ?? {}
        const updatedVideo = preserveSegmentation
            ? await this.addVideo(video)
            : await this.addVideoWithDefaultSegment({ video })
        const oldBaseSegments = videoToCopyFrom.getVisibleBaseSegments()
        if (preserveSegmentation) {
            for (const segment of oldBaseSegments) {
                // The new recording does not have patches, so we can use the time as the new position
                const newSegment = await updatedVideo.addSegment({ position: segment.time })
                const { documents, audioClips, transcriptions } = segment.actualSegment(this)

                await Promise.all([
                    newSegment.copyPassageSegmentDocuments(documents, warnAboutOldData),
                    newSegment.copyAudioClips(audioClips, warnAboutOldData),
                    newSegment.copyTranscriptions(transcriptions, warnAboutOldData)
                ])
            }
        } else {
            const oldOnTopSegments = videoToCopyFrom.visibleSegments(this)

            // Squash all segment metadata into a single segment
            const firstSegment = updatedVideo.getVisibleBaseSegments()[0]

            const hasTranscription = oldOnTopSegments.some((segment) => {
                const trans = segment.getCurrentTranscription()
                return trans && trans.text.trim() !== ''
            })
            if (hasTranscription) {
                const transcription = videoToCopyFrom.getTranscription({ passage: this, project })
                const newTranscription = firstSegment.createTranscription()
                newTranscription.text = transcription.text
                if (warnAboutOldData) {
                    newTranscription.modDate = transcription.modDate
                }
                await firstSegment.addTranscription(newTranscription, warnAboutOldData)
            }

            const hasBackTranslation = oldOnTopSegments.some((segment) => {
                const trans = segment.getCurrentBackTranslation()
                return trans && trans.text.trim() !== ''
            })
            if (hasBackTranslation) {
                const backTranslation = videoToCopyFrom.getBackTranslation({ passage: this, project })
                const newBackTranslation = firstSegment.createPassageSegmentDocument()
                newBackTranslation.text = backTranslation.text
                if (warnAboutOldData) {
                    newBackTranslation.modDate = backTranslation.modDate
                }
                await firstSegment.addPassageSegmentDocument(newBackTranslation, warnAboutOldData)
            }
        }

        await Promise.all([
            updatedVideo.copyMarkers(videoToCopyFrom, this, 'reference', warnAboutOldData),
            updatedVideo.copyMarkers(videoToCopyFrom, this, 'biblicalTerm', warnAboutOldData)
        ])

        return updatedVideo
    }

    async addVideoWithDefaultSegment({ video }: { video: PassageVideo }) {
        const _video = await this.addVideo(video)
        await _video.addSegment({ position: 0 })
        const updatedVideo = this.videos.find((v) => _video._id === v._id)
        if (!updatedVideo) {
            throw Error('Could not find video we just added segment to')
        }

        return updatedVideo
    }

    async addNewBaseVideo({
        video,
        videoToCopyFrom,
        project,
        options
    }: {
        video: PassageVideo
        videoToCopyFrom?: PassageVideo
        project: Project
        options?: { preserveSegmentation?: boolean; warnAboutOldData?: boolean }
    }) {
        const newVideo = videoToCopyFrom
            ? await this.addVideoFromExisting({
                  video,
                  videoToCopyFrom,
                  project,
                  options
              })
            : await this.addVideoWithDefaultSegment({ video })

        // This hidden segment is used when appending a recording
        if (newVideo.isAudioOnly()) {
            await newVideo.addSegment({ position: newVideo.duration - HIDDEN_SEGMENT_LENGTH_SECONDS, isHidden: true })
        }
        return newVideo
    }

    async addVideo(video: PassageVideo) {
        await this.db.put(video.toDocument())
        const _video = this.findVideo(video._id)
        if (!_video) {
            throw Error('could not find video we just added!')
        }
        return _video
    }

    async addPatchVideo({
        baseRecording,
        patch,
        baseSegment,
        onTopSegment
    }: {
        baseRecording: PassageVideo
        patch: PassageVideo
        baseSegment: PassageSegment
        onTopSegment: PassageSegment
    }) {
        if (this.findVideo(patch._id)) throw Error('Patch already added')

        // Add patch to list of videos for this passage.
        // Persist it to DB.
        patch.isPatch = true
        const savedPatch = await this.addVideo(patch)

        const { labels, references, cc, documents, audioClips, transcriptions } = onTopSegment
        const newSegment = await savedPatch.addSegment({ position: 0, labels, references, cc })

        const startTime = onTopSegment.time
        const endTime = onTopSegment.time + onTopSegment.duration
        const visibleTermMarkers = baseRecording
            .getVisibleBiblicalTermMarkers(this)
            .filter((marker) => marker.time >= startTime && marker.time <= endTime)

        const visibleVerseReferenceMarkers = baseRecording
            .getVisibleReferenceMarkers(this)
            .filter((marker) => marker.time >= startTime && marker.time <= endTime)

        // copy all the data to the patch and the segment on the patch
        await Promise.all([
            newSegment.copyPassageSegmentDocuments(documents, true),
            newSegment.copyAudioClips(audioClips, true),
            newSegment.copyTranscriptions(transcriptions, true),
            savedPatch.copyMarkersFromSingleSegment(onTopSegment, visibleTermMarkers),
            savedPatch.copyMarkersFromSingleSegment(onTopSegment, visibleVerseReferenceMarkers)
        ])

        await baseSegment.addVideoPatchToHistory(savedPatch)
        await baseRecording.updateVersion() // force display of video to redraw

        baseRecording.log(this, 'addPatchVideo DONE')

        const newVideo = this.videos.find((v) => v._id === patch._id)
        if (!newVideo || newVideo.segments.length !== 1) {
            throw new Error('Error creating patch video')
        }

        return newVideo
    }

    async deletePatchVideo(existingVideo: PassageVideo, patch: PassageVideo, segment: PassageSegment) {
        const exists = this.videos.find((v) => v._id === patch._id)
        if (!exists || !patch.isPatch || !segment.videoPatchHistory.includes(patch._id)) {
            return
        }
        const { notes, glosses } = patch
        for (const note of notes) {
            for (const item of note.items) {
                await note.removeItem(item._id)
            }
            await patch.removeNote(note._id)
        }
        for (const gloss of glosses) {
            await patch.removeGloss(gloss._id)
        }
        await segment.removeVideoPatchFromHistory(patch)
        await this.removeVideo(patch._id)
        await existingVideo.updateVersion()
    }

    async removeVideo(_id: string) {
        await remove(this.videos, _id)
    }

    async undeleteVideo(video: PassageVideo) {
        if (video.removed) {
            const doc = video._toDocument({})
            doc.removed = false
            await this.db.put(doc)
        }
    }

    async uploadFile(file: Blob, projectName: string, creationDate?: Date): Promise<PassageVideo> {
        const video = this.createVideo(projectName, creationDate)
        const result = await saveRecording(file, video.url)
        if (result.type === 'error') {
            throw new Error(result.error)
        }

        const { url, mimeType, duration } = result
        video.mimeType = mimeType
        video.url = url
        video.duration = duration
        return video
    }

    videod() {
        return this.videos.length > 0
    }

    get videosNotDeleted() {
        // Videos with _rev === 0 are still being updated by DBAcceptor and should be
        // ignored until that process is completed.
        return this.videos.filter((video) => !video.removed && !video.isPatch && video._rev > 0)
    }

    get latestVideo() {
        return this.videosNotDeleted.length ? this.videosNotDeleted.slice(-1)[0] : undefined
    }

    // use passage references as the source of truth, or else use verse references
    get defaultReferences() {
        if (this.references.length) {
            return this.references
        }

        if (this.latestVideo) {
            return this.latestVideo.getVisibleRefRanges(this)
        }

        return []
    }

    getDefaultVideo(_id: string) {
        // ensure we return null instead of undefined
        return _.findWhere(this.videosNotDeleted, { _id }) ?? this.latestVideo ?? null
    }

    async setDifficulty(difficulty: number) {
        difficulty = difficulty || 0

        if (difficulty < 0 || this.difficulty === difficulty) {
            return
        }

        const doc = this._toDocument({})
        doc.difficulty = difficulty
        await this.db.put(doc)
    }

    async setContentType(contentType: PassageContentTypes) {
        if (contentType === this.contentType) {
            return
        }

        const doc = this._toDocument({})
        doc.contentType = contentType
        await this.db.put(doc)
    }

    // Find video in this passage.
    // Returns undefined if not found.
    // _id can be for a video or any of its subobject (e.g. PassageNote)
    findVideo(_id: string) {
        const video = this.videos.find((v) => _id.startsWith(v._id))
        if (!video) {
            // log(`###findVideo failed passage=${this._id}, video=${_id}`)
        }

        return video
    }

    findSegment(_id: string) {
        const video = this.findVideo(_id)
        const segment = video?.getAllBaseSegments().find((s) => _id.startsWith(s._id))

        return segment
    }

    // Find note in this passage.
    // Returns undefined if not found.
    // _id can be for a note or any of its subobject (e.g. PassageNoteItem)
    findNote(_id: string) {
        const video = this.findVideo(_id)
        const note = video?.notes.find((n) => _id.startsWith(n._id))
        if (!note) {
            // log(`###findNote failed passage=${this._id}, note=${_id}`)
        }

        return note
    }

    firstUnviewedNote(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.firstUnviewedNoteAfterDate(this, username, cutoff, includeConsultantOnlyNotes) || null
    }

    firstUnresolvedNoteOnLatestVideo(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes) || null
    }

    getUnresolvedNote(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        const newestToOldestVideos = [...this.videosNotDeleted].reverse()
        for (const video of newestToOldestVideos) {
            const note = video.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes)
            if (note) {
                return note
            }
        }
        return null
    }

    firstUnviewedVideo(username: string, cutoff: Date) {
        const videos = this.videosNotDeleted
        const mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.isUnviewedAfterDate(username, cutoff) ? mostRecentVideo : null
    }

    getVersionIndex(passageVideo: PassageVideo) {
        const allVersions = this.videos.filter((v) => !v.isPatch)
        return allVersions.findIndex((v) => v._id === passageVideo._id)
    }
}

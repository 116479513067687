/* eslint-disable react/no-array-index-key */

import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { ExegeticalResourceSelector } from './ExegeticalResourceSelector'
import { StepJsonRenderer } from './StepJsonRenderer'
import { StepMediaRenderer } from './StepMediaRenderer'
import { fetchPericopes, Pericope, Step } from '../../resources/ExegeticalResources'
import { RefRange } from '../../resources/RefRange'
import { useAppRoot } from '../app/RootContext'
import { AudioFetchPlayer } from '../audio/AudioFetchPlayer'
import { ReferenceSearchInput } from '../referenceInput/ReferenceSearchInput'
import { DEFAULT_LANGUAGE_CODE } from '../utils/Languages'

import './ExegeticalResources.css'

const BODY_CLASS = 'resource-body'

interface ExegeticalResourceViewerProps {
    persistenceTag: string
    idSuffix: string
    passageReferences: RefRange[]
}

export const ExegeticalResourceViewer = ({
    persistenceTag,
    idSuffix,
    passageReferences
}: ExegeticalResourceViewerProps) => {
    const { t, i18n } = useTranslation()
    const appRoot = useAppRoot()
    const { rt } = appRoot
    const uiLanguage = i18n.language ?? DEFAULT_LANGUAGE_CODE
    const [language, setLanguage] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const [refs, setRefs] = useState<RefRange[]>([])
    const [pericopesMap, setPericopesMap] = useState<Map<string, Pericope[]> | null>(null)
    const [stepsMap, setStepsMap] = useState<Map<string, Step> | null>(null)

    const [selectedStepId, setSelectedStepId] = useState(rt?.getDefault(persistenceTag) || '')
    const [errored, setErrored] = useState(false)

    const setStepId = (stepId: string) => {
        rt?.setDefault(persistenceTag, stepId)
        setSelectedStepId(stepId)
    }

    useEffect(() => {
        const fetchResources = async () => {
            const handleNoGuides = () => {
                setPericopesMap(null)
                setStepsMap(null)
                setLanguage(null)
            }

            // Helper functions for the actual fetching
            const handleFetch = async (lang: string) => {
                if (!refs.length) {
                    handleNoGuides()
                    setLoading(false)
                    return
                }

                const refPericopesMap = await fetchPericopes(lang, refs)
                setPericopesMap(refPericopesMap)

                const refStepsMap: Map<string, Step> = new Map()
                refPericopesMap?.forEach((pericopes) => {
                    pericopes.forEach(({ steps }) => {
                        steps.forEach((step) => refStepsMap.set(step.id, step))
                    })
                })
                setStepsMap(refStepsMap)

                setLanguage(lang)
                setLoading(false)
            }

            const handleFetchError = (error: unknown, lang: string) => {
                console.error(`Error while fetching Exegetical Resources for language ${lang}`, error)
                handleNoGuides()
                setLoading(false)
            }

            // Attempt to fetch first in the selected locale
            try {
                setLoading(true)
                await handleFetch(uiLanguage)
            } catch (error) {
                // Attempt to fetch in the fallback locale
                if (uiLanguage !== DEFAULT_LANGUAGE_CODE) {
                    try {
                        await handleFetch(DEFAULT_LANGUAGE_CODE)
                    } catch (errorFallbackLng) {
                        handleFetchError(errorFallbackLng, DEFAULT_LANGUAGE_CODE)
                    }
                } else {
                    handleFetchError(error, uiLanguage)
                }
            }
        }

        fetchResources()
    }, [uiLanguage, refs])

    if (!rt) return null

    const dir = language === DEFAULT_LANGUAGE_CODE ? 'ltr' : i18n.dir()

    const step = stepsMap?.get(selectedStepId)

    return (
        <div className="resources-viewer">
            <div className="resources-viewer-toolbar">
                <ReferenceSearchInput
                    {...{
                        refInput: rt,
                        setRefs,
                        stickyReferenceId: `${persistenceTag}Reference`,
                        errored,
                        setErrored,
                        passageReferences
                    }}
                />
                {!loading && pericopesMap && (
                    <ExegeticalResourceSelector
                        pericopesMap={pericopesMap}
                        stepId={selectedStepId}
                        setStepId={setStepId}
                        idSuffix={idSuffix}
                    />
                )}
            </div>
            {loading && t('loading')}
            {!loading && !pericopesMap && t('exegeticalResourcesNone')}
            {!loading && stepsMap && selectedStepId && language && (
                <>
                    {step?.audio && (
                        <>
                            <div className="resource-audio-player">
                                <AudioFetchPlayer fetchBlob={step.audio} />
                            </div>
                            <div className="resource-audio-separator" />
                        </>
                    )}
                    {step?.json ? (
                        <StepJsonRenderer className={BODY_CLASS} dir={dir} jsonString={step.json} />
                    ) : (
                        <div
                            className={BODY_CLASS}
                            dir={dir}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: step?.html ?? '' }}
                        />
                    )}
                    {step?.media && step?.media.length > 0 && (
                        <div className={BODY_CLASS} dir={dir}>
                            <StepMediaRenderer language={language} media={step.media} />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

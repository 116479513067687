import { TFunction } from 'react-i18next'

import { CacheType } from '../../../types'

export const getCacheTypeLabel = (cacheType: CacheType, t: TFunction) => {
    if (cacheType === CacheType.IMAGES) {
        return t('bibleImages')
    }

    return t(cacheType)
}

import { getAudioResponse, getTimeCodesResponse } from './AudioResource'
import { EnhancedResources } from './EnhancedResources'
import {
    ExegeticalResourcePathRequest,
    fetchChapterPericopesResponse,
    fetchExegeticalResourceBookIndexes,
    fetchExegeticalResourceBookIndexesResponse,
    fetchMediaItemResponse,
    fetchPericopeAudioFile,
    fetchPericopeMediaIndex,
    fetchPericopeMediaIndexResponse,
    getExegeticalResourcePaths
} from './ExegeticalResources'
import { ImageMetadata } from './ImageMetadata'
import { ImageResolution, MARBLEImages } from './MARBLEImages'
import { RefRange } from './RefRange'
import { chunkArray, deduplicateArrayByField, processChunksAsync } from '../components/utils/Helpers'
import { MediaType, PublishedBible } from '../types'

const SAFE_FETCH_CHUNK_SIZE = 20

const getChunkedChapters = (bookNumber: number, versification: string) => {
    const allChapters = RefRange.getAllChaptersInBook(bookNumber, versification)
    return chunkArray(allChapters, SAFE_FETCH_CHUNK_SIZE)
}

const isCacheableResponse = (response?: Response) => response?.status === 200

const filterResponses = (responses: (Response | undefined)[]) =>
    responses.filter((response) => isCacheableResponse(response)) as Response[]

export const cacheImagesInBook = async ({ bookNumber }: { bookNumber: number }) => {
    const imagesVersification = 'English'
    const chunks = getChunkedChapters(bookNumber, imagesVersification)

    // Find out which images we need to download
    const allImages = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            const images = (await Promise.all(chapters.map((chapter) => MARBLEImages.fetchInfo(chapter)))).flat()
            return deduplicateArrayByField(images, 'id')
        })
    ).flat()

    // Then fetch all the images
    const allUniqueImages = deduplicateArrayByField(allImages, 'id')
    const imageResolutions = Object.values(ImageResolution)
    const imageResponses = (
        await processChunksAsync(
            chunkArray(allUniqueImages, Math.floor(SAFE_FETCH_CHUNK_SIZE / imageResolutions.length)),
            async (images: ImageMetadata[]) => {
                return Promise.all(
                    images.flatMap((image) => imageResolutions.map((resolution) => fetch(image.imagePath(resolution))))
                )
            }
        )
    ).flat()

    // Fetch image metadata again, because we need to return the responses
    const imageMetadataResponses = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            return (await Promise.all(chapters.map((chapter) => MARBLEImages.getMARBLEImagesResponse(chapter)))).flat()
        })
    ).flat()

    return filterResponses([...imageResponses, ...imageMetadataResponses])
}

const cacheSingleExegeticalResourceInBook = async ({
    language,
    bookIndexes,
    pericopeMedia,
    bookNumber,
    mediaType
}: ExegeticalResourcePathRequest & { bookNumber: number }) => {
    const fetch = (item: string) => {
        if (mediaType === MediaType.TEXT) {
            return fetchChapterPericopesResponse(language, item)
        }

        if (mediaType === MediaType.AUDIO) {
            return fetchPericopeAudioFile(language, item)
        }

        return fetchMediaItemResponse(item)
    }

    const allItems = getExegeticalResourcePaths({ language, bookIndexes, pericopeMedia, bookNumber, mediaType })
    const responses = (
        await processChunksAsync(chunkArray(allItems, SAFE_FETCH_CHUNK_SIZE), async (items: string[]) => {
            return Promise.all(items.map((item) => fetch(item)))
        })
    ).flat()

    return filterResponses(responses)
}

export const cacheExegeticalResourceInBook = async ({
    language,
    bookNumber,
    mediaType
}: {
    language: string
    bookNumber: number
    mediaType: MediaType
}) => {
    const indexResponse = await fetchExegeticalResourceBookIndexesResponse(language)
    const pericopeMediaIndexResponse = await fetchPericopeMediaIndexResponse()
    if (!indexResponse || !pericopeMediaIndexResponse) {
        return []
    }

    // We need to fetch the indexes twice because we need both the response and the deserialized response
    const { bookIndexes } = await fetchExegeticalResourceBookIndexes(language)
    const { pericopeMedia } = await fetchPericopeMediaIndex()

    const moreResponses = await cacheSingleExegeticalResourceInBook({
        language,
        bookNumber,
        bookIndexes,
        pericopeMedia,
        mediaType
    })

    // Non-text media must be downloaded with text in order for it to be displayed
    if (mediaType !== MediaType.TEXT) {
        const textResponses = await cacheSingleExegeticalResourceInBook({
            language,
            bookIndexes,
            pericopeMedia,
            bookNumber,
            mediaType: MediaType.TEXT
        })
        return filterResponses([indexResponse, pericopeMediaIndexResponse, ...moreResponses, ...textResponses])
    }

    return filterResponses([indexResponse, pericopeMediaIndexResponse, ...moreResponses])
}

export const cachePublishedBibleBook = async ({
    bookNumber,
    bibleVersion,
    mediaType
}: {
    bookNumber: number
    bibleVersion: PublishedBible
    mediaType: MediaType
}) => {
    const chunks = getChunkedChapters(bookNumber, bibleVersion.versification)
    const fetch = (chapter: string) =>
        mediaType === MediaType.TEXT
            ? Promise.all([EnhancedResources.fetchResponse(bibleVersion.id, chapter)])
            : Promise.all([getAudioResponse(bibleVersion.id, chapter), getTimeCodesResponse(bibleVersion.id, chapter)])

    const responses = (
        await processChunksAsync(chunks, async (chapters: string[]) => {
            return (await Promise.all(chapters.map((chapter) => fetch(chapter)))).flat()
        })
    ).flat()

    return filterResponses(responses)
}

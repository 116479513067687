// SegmentToolbar allows controlling playing and recording

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { OldSegmentDropdown } from './OldSegmentDropdown'
import { SegmentApproval } from './SegmentApproval'
import SegmentDeleter from './SegmentDeleter'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { RecordingType } from '../../types'
import { PatchFilePicker } from '../filePickers/BaseRecordingFilePicker'
import { PlayButton, PauseButton, RecordButton, StopButton, SlttHelp, GenericIconButton } from '../utils/Buttons'
import { PatchVideoDeleter } from '../video/PatchVideoDeleter'
import { AVTTRecordingState } from '../video/VideoRecorder'

import '../utils/Buttons.css'

interface ISegmentToolbar {
    rt: Root
    segment: PassageSegment
    oldVersionOptions: PassageVideo[]
    isAppendedSegment: boolean
    chooseVersionToPlay: (id: string) => void
    play: () => void
    pause: () => void
    record: (recordingType: RecordingType) => void
    stopRecording: () => void
    baseSegment: PassageSegment
    className: string
    recordingState: AVTTRecordingState
    isNotPlayingNorRecording: boolean
    isInReview: boolean
    recordEnabled: boolean
}

const SegmentToolbar = observer(
    ({
        rt,
        record,
        stopRecording,
        chooseVersionToPlay,
        oldVersionOptions,
        isAppendedSegment,
        play,
        pause,
        segment,
        className,
        recordingState,
        isNotPlayingNorRecording,
        isInReview,
        recordEnabled
    }: ISegmentToolbar) => {
        const { t } = useTranslation()
        const {
            playing,
            passage,
            passageVideo,
            passageSegment,
            recording,
            iAmTranslatorForPassage,
            iAmInterpreter,
            canPlayThrough
        } = rt
        const selectionPresent = rt.timeline.selectionPresent()
        const patchableSelectionPresent = rt.patchableSelectionPresent()

        const segments = passageVideo?.getVisibleBaseSegments() ?? []

        const compressingVideo = passage?.videoBeingCompressed ?? false

        const enabled = !!(passageVideo && passageSegment) && !compressingVideo
        const playEnabled = canPlayThrough && !recording && enabled && segments.length > 1

        let recordTooltip = t('Record patch for this segment.')
        if (selectionPresent) {
            recordTooltip = t('Record patch for the selection.')
            if (!patchableSelectionPresent) {
                recordTooltip += ` ${t('Patch selections cannot cross segments boundaries.')}`
            }
        }

        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')

        // When the segment is hidden (i.e., the user appended the recording), do not include
        // the first patch, since it functions as the original recording
        const oldSegmentDropdownShown = Boolean(
            passageSegment && passageSegment.videoPatchHistory.length > (passageSegment.isHidden ? 1 : 0)
        )

        const playButtonClass = `sl-segment-toolbar-button`

        const index = segments.findIndex((s) => s._id === passageSegment?._id)

        const previousSegmentIsPatch = index > 0 ? segments[index - 1].isPatched : false

        const actualSegment = passage && passageSegment?.actualSegment(passage)
        const forwardEnabled = !!actualSegment && rt.currentTime + 1 < actualSegment.time + actualSegment.duration
        const backwardsEnabled = !!actualSegment && rt.currentTime - 1 > actualSegment.time

        return (
            <div className={`segment-toolbar ${className}`}>
                <div className="segment-toolbar-first-group">
                    {playing && (
                        <>
                            <PauseButton
                                className="sl-segment-toolbar-button"
                                enabled={enabled}
                                tooltip={t('Pause.')}
                                onClick={pause}
                            />
                            {oldSegmentDropdownShown && (
                                <SlttHelp id="patches" tooltip={t('Play a previous version this segment.')} place="top">
                                    <OldSegmentDropdown
                                        dateFormatter={rt.dateFormatter}
                                        options={oldVersionOptions}
                                        enabled={false}
                                        onSelect={chooseVersionToPlay}
                                        isAppendedSegment={isAppendedSegment}
                                    />
                                </SlttHelp>
                            )}
                            <GenericIconButton
                                iconName="fa-rotate-left"
                                iconType="fas"
                                className={playButtonClass}
                                enabled={false}
                                onClick={() => {}}
                                tooltip={t('goBack1Second')}
                            />
                            <GenericIconButton
                                iconName="fa-rotate-right"
                                iconType="fas"
                                className={playButtonClass}
                                enabled={false}
                                onClick={() => {}}
                                tooltip={t('goForward1Second')}
                            />
                            <SlttHelp id="patches" tooltip={recordTooltip} place="top">
                                <RecordButton
                                    tooltip=""
                                    className="sl-segment-toolbar-button sl-record-patch-button"
                                    enabled={false}
                                    selectionPresent={selectionPresent}
                                    onClick={() => {}}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {!playing && !recording && (
                        <>
                            <span className="segment-toolbar-play-group">
                                <PlayButton
                                    tooltip={t('Play')}
                                    className={playButtonClass}
                                    enabled={playEnabled}
                                    selectionPresent={false}
                                    onClick={play}
                                />
                                {oldSegmentDropdownShown && (
                                    <SlttHelp
                                        id="patches"
                                        tooltip={t('Play a previous version this segment.')}
                                        place="top"
                                    >
                                        <OldSegmentDropdown
                                            dateFormatter={rt.dateFormatter}
                                            options={oldVersionOptions}
                                            enabled={!!enabled}
                                            onSelect={chooseVersionToPlay}
                                            isAppendedSegment={isAppendedSegment}
                                        />
                                    </SlttHelp>
                                )}
                                <GenericIconButton
                                    iconName="fa-rotate-left"
                                    iconType="fas"
                                    className={playButtonClass}
                                    enabled={backwardsEnabled || playing}
                                    onClick={() => rt.adjustCurrentTime(-1)}
                                    tooltip={t('goBack1Second')}
                                />
                                <GenericIconButton
                                    iconName="fa-rotate-right"
                                    iconType="fas"
                                    className={playButtonClass}
                                    enabled={forwardEnabled || playing}
                                    onClick={() => rt.adjustCurrentTime(1)}
                                    tooltip={t('goForward1Second')}
                                />
                            </span>
                            <SlttHelp id="patches" tooltip={recordTooltip} place="top">
                                <RecordButton
                                    tooltip=""
                                    className="sl-segment-toolbar-button sl-record-patch-button"
                                    enabled={recordEnabled}
                                    selectionPresent={selectionPresent}
                                    onClick={() => {
                                        record(RecordingType.PATCH)
                                    }}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {recording && (
                        <StopButton
                            className="sl-segment-toolbar-button segment-stop-button"
                            enabled={stopEnabled}
                            onClick={stopRecording}
                            tooltip={t('Stop recording.')}
                        />
                    )}
                    {passage && (
                        <PatchFilePicker
                            enabled={recordEnabled}
                            rt={rt}
                            passage={passage}
                            className="sl-segment-toolbar-button patch-upload-file-button"
                        />
                    )}

                    {!recording &&
                        passage &&
                        passageSegment &&
                        passageVideo &&
                        passageSegment.videoPatchHistory.length > 0 && (
                            <PatchVideoDeleter
                                {...{
                                    enabled:
                                        enabled &&
                                        iAmTranslatorForPassage(passage) &&
                                        isNotPlayingNorRecording &&
                                        !isInReview,
                                    deleter: { segment: passageSegment, rt, passage, passageVideo }
                                }}
                            />
                        )}
                </div>

                <div className="segment-toolbar-middle-group">
                    <SegmentApproval {...{ enabled: !!enabled && isNotPlayingNorRecording, segment }} />
                </div>

                <div className="segment-toolbar-last-group">
                    {passageSegment && passageVideo && (
                        <SegmentDeleter
                            segment={passageSegment}
                            rt={rt}
                            enabled={
                                iAmInterpreter &&
                                isNotPlayingNorRecording &&
                                passageSegment._id !== segments[0]._id &&
                                !passageSegment.isPatched &&
                                !previousSegmentIsPatch
                            }
                            passageVideo={passageVideo}
                        />
                    )}
                </div>
            </div>
        )
    }
)

export default SegmentToolbar

import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { VersionHistoryEntry } from '../types'

export class PassageSegmentTranscription extends DBObject {
    @observable text = ''

    @observable versionHistory: VersionHistoryEntry[] = []

    private static model = 19

    toDocument(useExistingModDate?: boolean) {
        const { text } = this
        return this._toDocument({ text, model: PassageSegmentTranscription.model }, useExistingModDate)
    }

    copy() {
        let copy = new PassageSegmentTranscription(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setText(text: string) {
        // Users probably want to preserve formatting in their transcription
        if (this.text === text) {
            return
        }

        const doc = this._toDocument({ text, model: PassageSegmentTranscription.model })
        await this.db.put(doc)
    }
}

import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { VersionHistoryEntry } from '../types'

export class PassageSegmentDocument extends DBObject {
    @observable text = ''

    @observable versionHistory: VersionHistoryEntry[] = []

    toDocument(useExistingModDate?: boolean) {
        const { text } = this
        return this._toDocument({ text, model: 12 }, useExistingModDate)
    }

    copy() {
        let copy = new PassageSegmentDocument(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    async setText(text: string) {
        if (this.text.trim() === text.trim()) {
            return
        }

        const doc = this._toDocument({ text, model: 12 })
        await this.db.put(doc)
    }
}
